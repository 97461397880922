.container {
  margin-bottom: 15px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.inputGroupSimulation {
  display: flex;
  flex-direction: column;
}
.simulationGroup {
  display: flex;
  justify-content: space-between;
}

.selectInputSimulation {
  width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  margin-right: 10px; /* Add space between the inputs */
}

.inputGroup {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Each column takes up equal space */
  grid-template-rows: auto auto;
  column-gap: 1.2%;
}

.fullWidth {
  width: 94%;
}

.dateTime {
  width: 100%; /* Ensure each date picker container takes up full width */
  margin-bottom: 10px;
}

.inputField {
  width: 100%;
}

.inputField:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.selectInput,
.inputField {
  padding: 10px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  margin-right: 10px; /* Add space between the inputs */
}

.disabledInputField{
  padding: 10px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  margin-right: 10px; /* Add space between the inputs */
  opacity: 0.5;
  cursor: not-allowed;
}

.selectInput:last-child,
.inputField:last-child {
  margin-right: 0; /* Remove margin for the last element */
}

.label {
  color: #5e5e5e;
  font-size: 14px;
  font-family: "Brown", sans-serif;
  margin-bottom: 8px;
}

.customInput {
  border: 1px solid #ccc;
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Adjust width as necessary */
  background-color: white;
  cursor: pointer;
  height: 40px;
}

.customInput i.fa-calendar {
  color: #c50c0c; /* Adjust color as necessary */
}
.simulationHeading {
  color: #5e5e5e;
  font-size: 20px;
  font-weight: 400;
  font-family: "Brown", sans-serif;
  margin-bottom: 15px;
}
.loadingText {
  font-family: "Brown", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #686868;
}


.checkboxParametersWrapper {
  display: flex;
  flex-direction: column;
}

.checkboxRow {
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  margin-bottom: 10px; 
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  margin-left: -19px; 
}

.checkboxTable {
  width: 240px;
  border-collapse: collapse;
}

.checkboxTable td {
  text-align: left; 
}

.checkboxTable td {
  padding-left: 12px;
  padding-top: 10px;
}

.labels {
  color: #5e5e5e;
  font-size: 14px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

.blocks {
  margin-top: 10px;
  margin-bottom: 20px;
}

.checkboxLabel {
  color: #5e5e5e;
  font-size: 12px;
  font-family: "Brown", sans-serif;
  display: flex;
  align-items: center;
  cursor: not-allowed;
}

.simulationDurationlabel {
  color: #5E5E5E;
  font-size: 14px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

.simulationDuration {
  color: #5E5E5E;
  font-size: 14px;
  font-family: "Brown", sans-serif;
  line-height: 14px;
  font-weight: 700;
}

.inactiveLabel {
  color: #E5E5E5;
  font-size: 12px;
  font-family: "Brown", sans-serif;
  display: flex;
  align-items: center;
  cursor: not-allowed;
}


