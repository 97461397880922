/* NetworkErrorBanner.module.css */

/* Keyframes for slide-down animation */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.networkErrorBanner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  background-color: #FCF8E8; /* Slightly transparent red background */
  text-align: center;
  padding: 10px;
  font-size: 10px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slideDown 0.5s ease-out; /* Apply the slide-down animation */
}

.networkErrorText {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-left: 12px;
  color: #5E5E5E;
}

.networkRefreshText {
  font-family: "Brown", sans-serif;
  font-weight: bold;
  font-size: 16px;
  margin-left: 19px;
  color: #363636;
  text-decoration: underline;
  cursor: pointer;
}

.overlay {
  position: fixed; /* or absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(46, 46, 46, 0.33); /* Dimmed background color */
  z-index: 1000; /* Make sure it's above other content */
}
