.navHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid hsla(0, 0%, 37%, 0.4);
  margin-top: 18px;
  padding-bottom: 15px;
}

.leftSection {
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 4px;
}

.logo{
  margin-top: 2px;
  margin-right: 15px;
}

.logo img {
  height: 33.67px; 
  width: 44px;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.divider {
  width: 1px;
  height: 22px; /* Adjust height as needed */
  background-color: #484848; /* Adjust color as needed */
  margin: 0 20px; /* Adjust spacing as needed */
}

.welcomeMessage {
  font-size: 16px;
  color: #2f2f2f;
  font-family: "Brown", sans-serif;
  font-weight: 300;
}

.rightSection {
  display: flex;
  align-items: center;
  position: relative;
}

.adminText {
  font-size: 16px;
  color: #5e5e5e;
  margin-right: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  margin-bottom: 3px;
}

.profileIcon img {
  width: 49px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.logoRedirect {
  cursor: pointer;
}

.dropdownMenu {
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px 30px 15px 12px;
  border-radius: 4px;
}
.dropdownMenu:hover{
  background-color: rgba(217, 217, 217, 0.4) ;
}

.imgTextWrapper {
  display: flex;
  align-items: center; /* This will vertically center align items */
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  font-family: "Brown";
  border-radius: 2px;
}

.imgTextWrapper img {
  margin-right: 8px; /* Add space between the icon and the text */
  margin-top: 2px;
}

.imgTextWrapper span {
  font-size: 16px;
  color: #5e5e5e; /* Adjust the color as needed */
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f7f7f7;
}

.icon {
  margin-right: 12px;
}

.drawerMenu {
  position: fixed;
  top: 0;
  left: -100%; /* Start offscreen */
  width: 250px; /* Adjust as necessary */
  height: 100%;
  background-color: #fff;
  transition: 0.3s;
}

.drawerMenu.open {
  left: 0; /* Bring onscreen */
}

.drawer {
  position: fixed;
  top: 0;
  right: -250px; /* Hide the drawer */
  width: 250px; /* Drawer width */
  height: 100%;
  background: #fcf8e8;
  z-index: 9;
  transition: right 0.3s ease-in-out;
}

.drawerOpen {
  right: 0; /* Show the drawer */
}

/* Styles for the hamburger menu button */

@media (max-width: 769px) {
  .leftSection{
    padding-left: 0px;
  }
  .hamburgerMenu {
    display: flex; /* Show the hamburger menu on small screens */
  }
  .toggleNav {
    display: none;
  }
  .closeDrawerIcon {
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 9;
    -webkit-tap-highlight-color: transparent;
  }
  .drawer {
    position: fixed;
    top: 0;
    right: -250px; /* Hide the drawer */
    width: 250px; /* Drawer width */
    height: 100%;
    background: #fcf8e8;
    z-index: 8;
    transition: right 0.3s ease-in-out;
  }

  .drawerOpen {
    right: 0; /* Show the drawer */
  }
  .underModal {
    z-index: 5; /* Ensure this is lower than the modal's z-index */
    -webkit-tap-highlight-color: transparent;
  }
  .logo img {
    height: 27.55px; 
    width: 36px;
  }
  
}

/* RDPNav.module.css */
.customPopoverPaper {
  border-radius: 0 !important; /* Force removal of border-radius */
}
