.settingsContainer {
  font-family: "Arial", sans-serif;
  overflow: auto;
  height: 51vh;
  position: relative; 
}

.settingsContainer::-webkit-scrollbar {
  width: 10px;
}
 
.settingsContainer::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}


.settingsTitle {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #b89536;
}

.section {
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.sectionTitle {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  line-height: 22.86px;
  color: rgba(235, 192, 63, 1);
}

.inputGroup {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #363636;
}

.inputfirstName {
  padding: 10px;
}

.inputContainer {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.inputStatic {
  margin-left: 5px;
  color: #767676;
}

.inputDynamic {
  flex-grow: 1;
  color: #767676;
}

.visibilityToggle {
  background: none;
  border: none;
  cursor: pointer;
}

.inputLabel,
.select {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #eaeaea;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  height: 48px;
  color: rgba(47, 47, 47, 1);
}
.inputfirstName,
.inputLabel:focus {
  /* Override focus border color to stay black */
  outline: none; 
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #eaeaea;
  position: relative;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  height: 48px;
  border: none;
}

.helpLink {
  font-size: 14px;
  text-align: start;
  margin-top: 20px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}
.needHelpLink {
  color: rgba(104, 104, 104, 1);
}
.onboardingSection {
  text-align: center;
  font-size: 14px;
  text-align: start;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: rgba(235, 192, 63, 1) !important;
}
.onBoardingLink {
  color: rgba(235, 192, 63, 1) !important;
  text-decoration: none;
}

.inputRow {
  display: flex;
  align-items: center;
  max-width: 460px;
}

.greaterThanArrow {
  margin-left: 2px;
  align-items: center;
}
.inputSmall,
.inputLarge,
.input20,
.input80 {
  flex-grow: 1;
  margin-right: 10px;
}

.inputSmall {
  flex-basis: 20%;
  max-width: 20%;
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #eaeaea;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  border: none;
  color: rgba(54, 54, 54, 1);
  border-radius: 4px;
}

.inputLarge {
  flex-grow: 2;
  display: flex;
  align-items: center;
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  height: 48px;
  max-width: 76%;
  background-color: #eaeaea;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: rgba(54, 54, 54, 1);
}

.input20 {
  flex-basis: 19%;
  max-width: 19%;
}

.input80 {
  flex-basis: 79%;
  max-width: 79%;
}

.inputLabel {
  width: 100%;
  background-color: #f9f9f9;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  border: 1px solid #e5e5e5;
  height: 48px;
  color: #2f2f2f;
  border-radius: 4px;
}
.inputfirstName {
  width: 100%;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  border: 1px solid #f9f9f9;
  height: 48px;
  color: #686868;
  background-color: #f9f9f9;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #e5e5e5;
}

.inputRow > :last-child {
  margin-right: 0;
}

.settingFooter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.infoImg {
  margin-right: 3px;
}

.infoText {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #686868;
  font-size: 14px;
  margin-left: 1px;
}

.homeInfo {
  margin-right: 0px;
  font-size: 12px;
  display: flex; 
  justify-content: flex-end; 
  align-items: baseline;
  position: absolute; /* Position this element absolutely */
  bottom: 0; /* Align to the bottom of the container */
  right: 0; /* Align to the right of the container */
} 

.text{
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #686868;
}

@media (max-width: 600px) {
  .homeInfo {
    margin-right: 5px;
    font-size: 12px;
    display: flex; 
    justify-content: flex-end; 
    position: inherit;
  } 
}

@media (max-width: 767px) {
  .inputRow {
    max-width: 100%;
  }
  .paragraph {
    display: flex;
    align-items: flex-start;/* Aligns items to the center vertically */
  }
  
  .asterisk {
    display: flex;
    align-items: flex-start; 
  }

  .label {
    font-size: 14px;
    color: #686868;
  }

  .settingFooter {
    align-items: normal;
  }
  
}

@media (max-width: 768px) {
  .settingsTitle {
    display: none;
  }

}

@media (min-width: 1024px) {
  .homeInfo {
    margin-right: 5px;
    font-size: 12px;
    display: flex; 
    justify-content: flex-end; 
    position: inherit;
  } 
}

@media only screen and (width: 1024px) and (height: 1366px) {
  .homeInfo {
    margin-right: 0px;
    font-size: 12px;
    display: flex; 
    justify-content: flex-end; 
    align-items: baseline;
    position: absolute; /* Position this element absolutely */
    bottom: 0; /* Align to the bottom of the container */
    right: 0; /* Align to the right of the container */
  } 
}

.greaterThanArrow {
  margin-left: 5px; /* Keep your margin to space out the text and the icon */
  vertical-align: middle; /* Align the icon with the middle of the text line */
  color: #a2a2a2 !important;
}

.helpLink {
  display: flex; /* Use Flexbox */
  align-items: center; /* Vertically center the items in the container */
  font-size: 14px;
  text-align: start;
  margin-top: 20px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

.needHelpLink {
  color: #a2a2a2;
  font-size: 16px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  text-decoration: none;
  line-height: 1; /* Adjust the line height to ensure proper alignment with the icon */
}



.inputLabel {
  color: #686868 !important;
  font-weight: 400;
  font-family: "Brown", sans-serif;
  font-size: 16px;
}
