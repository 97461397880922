.progressHeader {
  background-color: #fdf8e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-bottom: 0;
}

.statusTitle {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 12px;
}

.progessContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.currentStepOne,
.currentStepTwo {
  flex-grow: 1;
  background-color: #fff;
  max-height: 58px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.currentStepOne {
  margin-right: 10px;
}

.currentStepTwo {
  margin-left: 10px;
}

.iconsContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.backgroundForProfileIcon {
  background-color: #ebc03f;
  height: 58px;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  width: 50px;
  text-align: center;
}

.backgroundForVrIcon {
  background-color: #ebc03f;
  height: 58px;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  width: 50px;
  text-align: center;
}

.backgroundForProfileIconImage {
  margin: 17px auto;
}

.backgroundForProfileIconImageVr {
  margin: 21px auto;
}

.statusCheckcontainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
}

.statusGroup {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.inprogress,
.verified,
.inprogressText {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-left: 12px;
  /* margin-right: 12px; */
  margin-right: 2px;
}

.inprogress {
  height: 24px;
  width: 24px;
  border: 1px solid #b89536;
  border-radius: 60%;
  background-color: transparent;
  display: flex; /* Align children using Flexbox */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  font-size: 16px; /* Optional: Adjust the font size as needed */
}

.inProgresslabelText {
  text-align: center;
  color: #b89536;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  margin-top: 3px;
  /* Remove margin or set it to 0 */
}

.verified {
  color: #016f4a;
}

.inprogressText {
  color: #b89536;
}

.statusIcon {
  margin-left: 12px;
}

.hrLine {
  margin: 13px auto;
  width: 24%;
  height: 1px;
}

/* ... existing styles ... */

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .progessContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    display: none;
  }
  .currentStepOne,
  .currentStepTwo {
    width: 90%; /* Adjust width to fit smaller screens */
    margin: 10px 0; /* Add vertical spacing between elements */
  }
  .statusIcon {
    margin: 0;
  }
  .inprogress,
  .verified,
  .inprogressText {
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .dropdownContainer {
    position: relative;
    width: 100%;
    margin-top: 10px;
  }

  .dropdownHeader {
    background-color: #ffffff;
    cursor: pointer;
    align-items: center;
    /* height: 45px; */
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; This will place the arrow at the end of the header */
    cursor: pointer;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;
  }

  .dropdownArrow {
    position: absolute;
    right: 12px;
  }

  .dropdownContent {
    width: 100%;
    background-color: #fff;
    z-index: 1;
    overflow: hidden;
    transition: max-height 0.7s ease-in-out; /* Keep this transition */
  }

  .dropdownContent {
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 0; /* Initial state is closed */
  }

  .dropdownContentOpen {
    max-height: 1000px; /* Adjust as needed for content */
  }

  .dropdownOption {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .dropdownIcon {
    margin-right: 10px;
  }

  .dropdownDivider {
    margin: 0;
    border-top: 1px solid #eee;
  }

  .dropdownHeaderContent {
    display: flex;
    align-items: center;
  }
  .currentStepTwo {
    display: none;
  }
  .statusTextWrapper {
    display: flex;
  }
  .profileInfoContainer {
    width: 100%;
  }
  .profileIconWrapper {
    display: flex; /* Align children in a row */
    align-items: center; /* Center children vertically */
  }
  .vrIconWrapper {
    display: flex; /* Align children in a row */
    align-items: center; /* Center children vertically */
  }

  .backgroundForProfileIcon {
    /* Ensure this container is also flex to center the image inside it */
    background-color: #ebc03f;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Other styles remain unchanged */
    height: 130px;
    width: 60px;
    border-bottom-left-radius: 0;
  }
  .backgroundForVrIcon {
    /* Ensure this container is also flex to center the image inside it */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Other styles remain unchanged */
    border-top-left-radius: 0;
    height: 125px;
    width: 60px;
  }
  .statusIcon {
    width: 24px; /* Set a fixed width */
    height: 24px; /* Set a fixed height */
    /* Other styles remain unchanged */
  }

  .statusTextWrapper {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Adjust spacing between icon and text as needed */
    /* Other styles remain unchanged */
  }

  .statusTextWrapper:not(:last-child) {
    margin-bottom: 10px; /* Adjust the gap size as needed */
  }
  /* Add a bottom margin to the profileIconWrapper to create a gap between the profile and VR sections */
  .profileIconWrapper {
    margin-bottom: -1px; /* Adjust this value based on your desired gap size */
  }
  .horizontalLine {
    height: 0.5px; /* Line thickness */
    background-color: #e5e5e5; /* Line color, adjust as needed */
    width: 100%; /* Line width */
    margin: 0.5px 0; /* Spacing above and below the line */
  }

  .statusWrapperContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .statusTextWrapper {
    display: flex;
    align-items: center;
    /* Ensure there's space for the vertical line */
    margin-right: 10px; /* Adjust as needed */
  }

  .verticalLine {
    width: 1px; /* Width of the line */
    background-color: #686868; /* Color of the line */
    height: 28px; /* Height of the line, adjust as needed */
    /* Add margins if you want to increase the spacing around the line */
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .statusTitle {
    /* display: none; */
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .backgroundForProfileIconImage {
    margin: 10px auto;
  }
  .inprogress,
  .inprogressText {
    margin-left: 5px;
  }
}

/* untouched label */
.untouchedProgressIcon {
  border: 0.5px solid #868686;
  margin-left: 12px;
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding-bottom: 3px;
}

.unTouchedText {
  color: #5e5e5e;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-left: 12px;
  margin-right: 12px;
}

.unTouchedLabelText {
  text-align: center;
  color: #5e5e5e;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  margin-top: 3px;
}

.statusTextWrappernone {
  display: none;
}

/* Adjusted .inprogress style */
.inprogress {
  height: 24px;
  min-width: 24px; /* Ensure the width is at least as large as the height */
  border: 1px solid #b89536;
  border-radius: 50%; /* Use 50% for perfect circles */
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-right: 0;
  padding-bottom: 3px;
}

/* Ensure .inProgresslabelText is centered and does not affect the circle's shape */
.inProgresslabelText {
  color: #b89536;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

/* Adjust the .statusGroup style if necessary to ensure it doesn't squeeze the circles */
.statusGroup {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

/* Additional adjustments for responsive design or specific scenarios */
@media (max-width: 768px) {
  .inprogress,
  .untouchedProgressIcon {
    margin-left: 10px; /* Adjust based on your design needs */
  }
  .progressHeader {
    padding: 15px;
    padding-bottom: 0px;
  }
}

.iconStyles {
  background-color: #ebc03f;
  height: 58px;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  /* width: 50px; */
  text-align: center;
}

