.userDetailsTable {
  width: 100%;
  font-family: "Brown";
  padding: 0 15px 5px 15px;
}

.tableHeader,
.tableRow {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.userIdHeader {
  width: 25%; /* Adjust this to the width of your User Id column */
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: rgba(47, 47, 47, 1);
}

.detailsHeader {
  display: flex;
  flex-grow: 1; /* Allows these elements to grow */
  justify-content: flex-end; /* Spreads out the child elements evenly */
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: rgba(47, 47, 47, 1);
}

.detailsHeader div {
  text-align: left !important;
}

.userDetails {
  display: flex;
  flex-grow: 1; /* Allows these elements to grow */
  justify-content: flex-end; /* Spreads out the child elements evenly */
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: rgba(94, 94, 94, 1);
}

.userDetails div:not(:last-child) {
  text-align: left !important;
}

/* Assign text-align and width for headers and cells */
.tableHeader > .detailsHeader > div,
.tableRow > .userDetails > div {
  width: 15%; 
  text-align: center;
}

.userId {
  width: 25%; /* Ensure the User ID cells match the header width */
  text-align: left; /* Align the User ID to the left */
  color: rgba(94, 94, 94, 1);
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

/* Apply the border to the entire table row instead of individual cells */
.tableRow {
  border-bottom: 0.5px solid rgba(167, 167, 167, 0.4);
  margin-bottom: 10px;
}

/* Additional styling */
.tableHeaderWrapper {
  margin: 0 20px;
}
.tableHeader {
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #a7a7a7; /* Add bottom border for the header */
  padding-bottom: 10px; /* Optional: Adds some padding below the text */
}

/* UserDetailsTable.module.css */
.noUsersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 55vh; /* Full height of the viewport */
  text-align: center;
}

.noUsersContainer img {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.noUserHeading {
  font-family: "Brown", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: rgba(94, 94, 94, 1);
  margin-top: 5vh;
}

.noUserDesc {
  font-size: 16px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: rgba(180, 180, 180, 1);
  margin-top: 10px;
}

/* Additional styling for your container, such as margins or padding, can go here */
.paginationContainer {
  display: flex;
  justify-content: flex-end; /* Aligns children (Pagination) to the right */
  align-items: baseline;
  margin-bottom: 15px;
  margin-top: 15px;
}

.pageText {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #b4b4b4 !important;
  font-family: "Brown", sans-serif !important;
}

.inactiveStatus {
  color: #a2a2a2;
}

.statusTextWrapper {
  display: flex;
  align-items: center;
}

.unTouchedText,
.verified,
.inprogressText {
  font-family: "Brown";
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
}

.unTouchedText {
  color: #5e5e5e;
}

.verified {
  color: #016f4a;
}

.inprogressText {
  color: #EBC03F;
}
.verticalLine {
  width: 1px; /* Width of the line */
  background-color: #686868; /* Color of the line */
  height: 20px; /* Height of the line, adjust as needed */
  margin-left: 6px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.headerText {
  font-family: "Brown";
  font-size: 16px;
  font-weight: 400;
  line-height: 12.32px;
  text-align: left;
  color: #868686;
}


@media (max-width: 1024px) {
  .tableHeader,
  .tableRow {
    min-width: 1150px;
  }
  .userId {
    margin: 0;
  }
  .userDetails,
  .detailsHeader {
    gap: 30px;
  }
  .userIdHeader {
    margin: 0;
  }
  .tableHeaderWrapper {
    overflow: auto;
  }
  .inprogress,
  .inprogressText {
    margin-left: 5px;
  }
  .inprogress,
  .verified,
  .inprogressText {
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-left: 10px;
  }
  .statusTextWrapper {
    display: flex;
    align-items: center;
  }
  .verticalLine{
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
