.w-100 {
  width: 100%;
}

.ParticipantLogin_imageSection__6FpNy {
  display: none;
}

.flex-align-center-justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-align-center-justify-space-bw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-align-center-justify-end {
  display: flex;
  align-items: center;
  justify-content: end;
}

.flex-column {
  display: flex;
  flex-flow: column;
}

input::placeholder {
  color: #a2a2a2 !important;
}

input:not(:disabled):-webkit-autofill,
input:not(:disabled):-webkit-autofill:hover,
input:not(:disabled):-webkit-autofill:focus,
input:not(:disabled):-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.react-switch {
  margin-right: 15% !important;
}

.MuiPaginationItem-text {
  color: #b4b4b4 !important;
  font-weight: 400 !important;
  font-family: "Brown", sans-serif !important;
  font-size: 16px !important;
  height: 25px !important;
  width: 27px !important;
  min-width: 27px !important;
  margin: 0 5px !important;
}

.MuiPaginationItem-root.Mui-selected {
  border-radius: 6px !important;
  background-color: #ebebeb !important;
}
.MuiPaginationItem-icon {
  font-size: 28px !important;
  font-weight: bold !important;
}
.MuiCheckbox-root {
  padding: 5px !important;
}

.react-datepicker__calendar-icon {
  right: 0 !important;
}
.react-datepicker__input-container {
  display: flex;
  align-items: center;
}
