.otpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.otpTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #9a9a9a;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

.otpInputs {
  display: flex;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
}

.otpInput {
  width: 3rem;
  height: 40px;
  margin: 0 2px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #5E5E5E;
  color: #363636;
  flex: 1;
  text-align: center;
  border-radius: 4px;
}

/* Firefox specific style */
@-moz-document url-prefix() {
  .otpInput {
    width: 3rem;
    height: 40px;
    margin: 0 2px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #5E5E5E;
    color: #363636;
    flex: 1;
    text-align: center;
    border-radius: 4px;
  }
}

.otpInput:focus {
  /* Override focus border color to stay black */
  border-color: #5E5E5E;
  outline: 1px solid #5E5E5E; 
}

.verifyButtonInactive {
  background-color: #e5e5e5;
  color: #a8a8a8;
  cursor: not-allowed;
  border-radius: 20px;
  padding: 10px 20px;
  border: none;
}

.verifyButtonActive {
  background-color: #ebc03f;
  color: #363636;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px 20px;
  border: none;
}

.verifyButtonActive:hover {
  background-color: #d1a635;
}

.verifyButtonInactive,
.verifyButtonActive {
  align-self: flex-start;
}

.verifyButton {
  background-color: #ebc03f;
  transition: background-color 0.3s;
  border-radius: 20px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  width: 130px;
  height: 40px;
  margin-top: 20px;
  color: #363636;
}

.verifyButtonActive {
  background-color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

.regenerateButtonError {
  background-color: #ebc03f;
}

.regenerateButton {
  color: #363636;
  border-radius: 20px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  margin-top: 20px;
  background-color: #ebc03f;
}
.regenerateButton:hover {
  background-color: #d1a635;
}

.invalidOtpInput {
  background-color: #f7d8d5;
  border: 1px ssolid #ef7777 !important;
}
.validOtpInput {
  background-color: none;
}

.btnGrp {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Check box style */
.consentContainer {
  display: flex;
  align-items: flex-start;
  padding: 2px;
  font-size: 12px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #999897;
}

.checkboxItem {
  margin-bottom: 10px;
}
.privacyPolicycheckBox {
  margin-top: 7px !important;
  margin-right: 5px;
}
.linkUnderLine {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .flexRow {
    display: none;
  }
  .verifyButtonInactive,
  .verifyButtonActive {
    align-self: center;
    width: 90%;
  }
  .btnGrp {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }
  .signinTitle {
    display: none;
  }
  .verifyButton {
    width: 80%;
  }
}

/* new */
.otpInstructionAndTimer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.resendButton {
  margin: 0;
  margin-top: 35px;
  border-radius: 18px;
  color: #A2A2A2;
  background-color: #E5E5E5;
  font-weight: 500;
}

.resendButtonActive {
  margin: 0;
  margin-top: 35px;
  border-radius: 18px;
  color: #2F2F2F;
  background-color: #ebc03f;
  font-weight: 400;
  font-family: "Brown", sans-serif;
  font-size: 16px;
  width: 50%;
}

.otpInstructionText {
  margin-right: auto; /* This pushes the timer to the far right */
}
.signInButton {
  margin: 0;
  margin-top: 35px;
  border-radius: 18px;
  color: #a2a2a2;
  font-weight: 400;
  font-size: 16px;
  font-family: "Brown", sans-serif;
  -webkit-tap-highlight-color: transparent;
  cursor: not-allowed;
}

.signInButtonActive {
  margin: 0;
  margin-top: 35px;
  border-radius: 18px;
  background-color: #ebc03f;
  color: #2f2f2f;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.resendButtonActive:hover {
  background-color: #d1a635;  
}
.signInButtonActive:hover {
  background-color: #d1a635;
}

.otpInstructionText{
  color: #363636;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.otpSubText{
  font-family: "Brown std";
  font-weight: bold;
  font-size: 14px;
  font-style: italic;
  color: rgba(79, 79, 79, 0.6);
  margin: 0;
}

/* Firefox specific style */
@-moz-document url-prefix() {
  .otpSubText {
    color: rgba(107, 107, 107, 0.7) !important;
  }
}

.resentText{
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #86868680;
}
.otpCount{
  font-family: "Brown", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color:rgba(235, 192, 63, 1);
}
.otpInputError {
  background-color: #E34A4733;
  border: 1px solid #E34A47;
}
.error {
  color: #E34A47;
  margin-bottom: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

@media (max-width: 768px) {
  .otpInstructionText{
    font-size: 14px;
  }
  .otpSubText{
    display: none;
  }
  .resendButtonActive{
    margin: 0;
    margin-top: 35px;
    border-radius: 18px;
    color: #2F2F2F;
    background-color: #ebc03f;
    font-weight: 400;
    font-family: "Brown", sans-serif;
    font-size: 16px;
    width: 100%;
  }
  .buttonContainer {
    padding-bottom: 10px;
  }
  .otpInputError {
    background-color: #ffffff33;
    border: 1px solid #E34A47 !important;
  }
}