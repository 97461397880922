.profileViewContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #fdf8e8 !important;
}

.imageSection {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 34vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.overlay {
  position: fixed; /* or absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed background color */
  z-index: 1000; /* Make sure it's above other content */
}
.imageSection::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, #000000 -33.93%, rgba(0, 0, 0, 0) 93.98%);
  z-index: 1;
}

.contentSection {
  background-color: #fdf8e8 !important;
  height: 51vh;
  width: 100%;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  justify-content: space-around;
  flex-direction: row;
  padding-bottom: 45px;
}


.verifying {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #b89536;
  font-size: 16px;
  margin-left: 3px;
}

.profileWelcomeText {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 42.88px;
  color: rgba(229, 229, 229, 1);
  position: relative;
  z-index: 2;
}
.settingButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;

  z-index: 3;
  width: 133px;
  height: 47px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 29px;
  color: rgba(54, 54, 54, 1);
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.subSection {
  flex-basis: 65%;
  overflow-y: auto;
  padding: 20px;
  border-radius: 14px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  transition: flex-grow 0.5s ease-in-out;
}


.subSection::-webkit-scrollbar {
  width: 10px;
}
 
.subSection::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}

.logoNeom {
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  z-index: 10;
}

.settingButton:hover {
  background-color: #d1a635;
}

.statusDropdown {
  display: none;
}

.logoReponsive{
  width: 65.33px; 
  height: 50px; 
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.diagnosticHeader{
  display: none;
}

@media (max-width: 768px) {
  .contentSection {
    display: flex;
    flex-direction: column;
  }

  .statusSection {
    width: 100%;
    height: auto;
    margin-bottom: 5px;
  }

  .logoReponsive{
    width: 44px; 
    height: 33.67px 
  }


  .imageSection {
    height: 35vh;
    align-items: flex-end;
  }

  .profileWelcomeText {
    font-size: 16px;
    line-height: 21.44px;
    margin-bottom: 8%;
  }
  .logoNeom {
    width: 39px;
    height: 49px;
  }
  .logoNeom {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 10;
  }

  .diagnosticHeader{
    display: flex;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    margin-bottom: 10px;
  }

  .subSection {
    border-radius: 5px;
    padding: 18px;
    padding-inline: 12px;
  }

}

.drawer {
  position: fixed;
  top: 0;
  right: -250px; /* Hide the drawer */
  width: 250px; /* Drawer width */
  height: 100%;
  background: #FCF8E8;
  z-index: 1000;
  transition: right 0.3s ease-in-out;
}

.drawerOpen {
  right: 0; /* Show the drawer */
}

/* Styles for the hamburger menu button */
.hamburgerMenu {
  cursor: pointer;
  display: none; /* Hide by default, show it only on small screens */
  flex-direction: column;
  justify-content: space-around;
  width: 18px;
  height: 17px;
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 1001; /* Above the drawer */
  -webkit-tap-highlight-color: transparent;
}

.crossButton {
  cursor: pointer;
  display: none; /* Hide by default, show it only on small screens */
  flex-direction: column;
  justify-content: space-around;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1001; /* Above the drawer */
  -webkit-tap-highlight-color: transparent;
}

.bar {
  height: 2px;
  width: 100%;
  background-color: #fff; /* or any color you prefer */
  border-radius: 10px;
}


@media (max-width: 768px) {
  .hamburgerMenu {
    display: flex; /* Show the hamburger menu on small screens */
  }
  .toggleNav{
    display: none;
  }
  .crossButton {
    display: flex; /* Show the cross button on small screens */
  }
}

