@font-face {
  font-family: "Brown";
  src: url("/public/fonts/lineto-brown-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Brown";
  src: url("/public/fonts/lineto-brown-regular.ttf")
    format("truetype");
  font-weight: normal; /* Regular */
  font-style: normal;
}
@font-face {
  font-family: "Brown std";
  src: url("/public/fonts/lineto-brownstd-thin.ttf")
    format("truetype");
  font-weight: normal; /* Regular */
  font-style: normal;
}

.flag-dropdown.open .country .flag {
  display: none; /* Hides the flag */
}
.react-tel-input .selected-flag .flag {
  display: none;
}

.flag-dropdown {
  margin-right: 400px;
}

.flag-dropdown.open .country .country-name {
  display: none; /* Hides the country name */
}
.PhoneNumberValidation_dropdown__Tm2pQ{
  height: 10px;
}

.css-13cymwt-control{
  height: 48px;
}
.css-13cymwt-control{
  min-height: 48px;
}
.css-16xfy0z-control{
  height: 48px;
}
.css-1fdsijx-ValueContainer{
  color: #545454;
}
.css-olqui2-singleValue{
  color: #686868 !important;
}

.css-t3ipsp-control{
  border-color:#CCCCCC !important;
  height:48px ;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-1xc3v61-indicatorContainer{
  color: #363636 !important;
}
.css-fv0s2z-control {
  border: 1px solid #5e5e5e !important;
}
.OTPInputComponent_privacyPolicycheckBox__oEOiD{
  border: 1px solid red !important;
}
.css-13cymwt-control{
  border-color: #868686 !important;
}
.css-1jqq78o-placeholder{
  color: #A2A2A2 !important;
}
.css-16xfy0z-control{
  background-color: #f9f9f9 !important;
  border: 1px solid #e5e5e5
}
/* .css-qbdosj-Input{
 margin-right: 0 !important;
} */
/* .css-1jqq78o-placeholder{
  margin: 0px !important;
} */

.css-170x2i9-control{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 5px !important;
}
.css-8wrbo8-control{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 5px !important;
}
.css-p6lmma-control:hover{
  border: 1px solid #868686 !important;
}
