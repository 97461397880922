.maincontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .mainSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .simulationHeading {
    color: #5e5e5e;
    font-size: 20px;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    margin-bottom: 45px;
    margin-top: 25px;
  }
  
  .blocks {
    width: 40%;
    margin-bottom: 20px;
  }

  .dateandtime{
    display: flex;
    flex-direction: column; /* Stack label above input */
    margin-bottom: 15px; /* Space between each block */
    width: 44.3%; /* Ensure full width usage */
    padding-left: 2.1%;
  }
  
  .inputField input::placeholder {
    color: #A2A2A2; /* Placeholder color */
    opacity: 1; /* Ensures placeholder is visible */
  }
  
  .labels {
    color: #5e5e5e;
    font-size: 14px;
    font-family: "Brown", sans-serif;
  }
  
  .customdatepicker {
    width: 95%;
    height: 40px;
    padding: 10px; 
    border-radius: 4px;  
    border: 1px solid #E5E5E5;  
    color: #1D1F22;
    font-size: 14px;
    font-family: "Brown", sans-serif;
    font-weight: 400;
  }

  .customdatepicker:focus {
    outline: none; /* Remove the default outline */
  }

  .inputField {
    width: 95%;
    height: 44px;
    padding-right: 3.3%; 
    border: 1px solid #E5E5E5; 
    border-radius: 4px; 
    padding-left: 10px; 
    cursor: pointer;
    color: #1D1F22;
    font-size: 14px;
    font-family: "Brown", sans-serif;
    margin-top: 10px;
  }

  .inputField:focus {
    outline: none; /* Remove the default outline */
  }

  .customTimeIcon {
    position: absolute;
    right: 8.3%; 
    top: 50%;
    transform: translateY(5%);
    pointer-events: none; /* Prevent icon from blocking input interaction */
    color: #A2A2A2;
    background-color: #fff;
  }

  .nextBtn {
    color: #a2a2a2;
    background-color: #e5e5e5;
    border-radius: 29px;
    width: 100%;
    margin-left: 0px;
    height: 44px;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }

  .nextBtnEnable{
    background-color: #ebc03f;
    border-radius: 29px;
    width: 100%;
    margin-left: 0px;
    height: 44px;
    color: #363636;
  }

  .footerBtnContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    width: 220px;
    margin-top: 25px;
  }

  .submittedFooter {
    width: 298px;
  }
  
  .disabledInput {
    background-color: #F9F9F9; 
    border-color: #E5E5E5; 
    cursor: not-allowed; 
  }
  
  .messageContainer {
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    gap: 8px; 
    text-align: center; /* Center text within its container */
    padding: 4px; 
  }

.messageText {
  font-size: 16px; 
  font-weight: 400; 
  color: #363636; 
  font-family: "Brown", sans-serif;
}
