.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 18px;
  z-index: 11;
  text-align: center;
  width: 533px;
  height: 200px;
  /* Further styling as needed */
}

.modalTitle {
  color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 22.86px;
}

.modalInfo {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #5e5e5e;
  margin-top: 10px;
}

.modalActions {
  margin: 30px auto;
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
}

.modalCancel {
  background: none;
  border: none;
  width: 130px;
  height: 40px;
  color: #363636;
  font-size: 16px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  border-radius: 43px;
}

.modalCancel:hover {
  background-color: #E5E5E5;
}

.modalConfirm {
  background-color: #ebc03f;
  border-radius: 43px;
  color: #363636;
  width: 144px;
  font-size: 16px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

.modalConfirm:hover {
  background-color: #d1a635;
}

@media (max-width: 600px) {
  .modalContent {
    width: 95%;
    margin: auto;
  }
  .modalActions {
    margin: 20px 20px;
  }
}
