.userDetailsTable {
  width: 100%;
  font-family: "Brown";
  padding: 0 15px 5px 15px;
}

.tableHeader,
.tableRow {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.userIdHeader {
  width: 50%; /* Adjust this to the width of your User Id column */
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: rgba(47, 47, 47, 1);
}

.detailsHeader {
  display: flex;
  flex-grow: 1; /* Allows these elements to grow */
  justify-content: flex-end; /* Spreads out the child elements evenly */
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: rgba(47, 47, 47, 1);
}

.detailsHeader div:not(:last-child) {
  text-align: left !important;
}

.detailsHeader div:last-child {
  width: 25% !important;
}

.userDetails {
  display: flex;
  flex-grow: 1; /* Allows these elements to grow */
  justify-content: flex-end; /* Spreads out the child elements evenly */
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: rgba(94, 94, 94, 1);
}

.userDetails div:not(:last-child) {
  text-align: left !important;
}

.userDetails div:last-child {
  width: 25% !important;
}

/* Assign text-align and width for headers and cells */
.tableHeader > .detailsHeader > div,
.tableRow > .userDetails > div {
  width: 50%; /* Adjust the width of these elements */
  text-align: center;
}

.userId {
  width: 50%; /* Ensure the User ID cells match the header width */
  text-align: left; /* Align the User ID to the left */
  color: rgba(94, 94, 94, 1);
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

/* Apply the border to the entire table row instead of individual cells */
.tableRow {
  border-bottom: 0.5px solid rgba(167, 167, 167, 0.4);
  margin-bottom: 10px;
}

/* Additional styling */
.tableHeaderWrapper {
  margin: 0 20px;
}
.tableHeader {
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #a7a7a7; /* Add bottom border for the header */
  padding-bottom: 10px; /* Optional: Adds some padding below the text */
}

/* UserDetailsTable.module.css */
.noUsersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 55vh; /* Full height of the viewport */
  text-align: center;
}

.noUsersContainer img {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.noUserHeading {
  font-family: "Brown", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: rgba(94, 94, 94, 1);
  margin-top: 5vh;
}
.noUserDesc {
  font-size: 16px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: rgba(180, 180, 180, 1);
  margin-top: 10px;
}

/* Additional styling for your container, such as margins or padding, can go here */
.paginationContainer {
  display: flex;
  justify-content: flex-end; /* Aligns children (Pagination) to the right */
  align-items: baseline;
  margin-bottom: 15px;
  margin-top: 15px;
}

.pageText {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #b4b4b4 !important;
  font-family: "Brown", sans-serif !important;
}

.inactiveStatus {
  color: #a2a2a2;
}
@media (max-width: 768px) {
  .tableHeader,
  .tableRow {
    min-width: 1150px;
  }
  .userId {
    margin: 0;
    width: 25%;
  }
  .userDetails,
  .detailsHeader {
    gap: 30px;
  }
  .userIdHeader {
    margin: 0;
    width: 25%;
  }
  .tableHeaderWrapper {
    overflow: auto;
  }
}

.userActions {
  text-align: center !important;
}
