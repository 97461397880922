.statusSection {
  background-color: #fff;
  padding-top: 27px;
  border-radius: 12px;
  width: 27%;
  margin-right: 10px;
  overflow-y: auto;
  flex: 0 1 69px;
  transition: flex-basis 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
}
.statusSection:hover {
  flex-basis: 28% !important;
}

.iconWithLabel {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 35px;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 44px;
  margin-right: 8px;
}

.iconLabel {
  font-size: 14px;
  color: #a2a2a2;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  overflow: hidden;
  width: 0;
  transition: width 0.5s ease-in-out;
}
.logOutIconLabel {
  color: #5e5e5e;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-left: 8px;
}

.statusSection:hover .iconLabel {
  width: auto;
  padding-left: 8px;
}
.logOutIcon {
  margin-top: auto; /* This will push the logout icon to the bottom */
  position: absolute; /* Absolute position the logout to the bottom */
  bottom: 20px; /* Space from the bottom */
  left: 0; /* Align to the left */
  width: 100%;
}
.activeIconLabel {
  font-size: 14px;
  color: #363636;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  overflow: hidden;
  width: 0;
  transition: width 0.5s ease-in-out;
}

.statusSection:hover .activeIconLabel {
  width: auto;
  padding-left: 8px;
}

.statusSection .logOutIconLabel {
  width: 0;
}

.iconWithLabel:hover .iconLabel,
.iconWithLabel:hover .activeIconLabel {
  width: auto;
}

.activeOption {
  background-color: #fcf8e8;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 23px; 
  margin-bottom: 25px;
  height: 49px;
}

.activeOption::before,
.activeOption::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 8px;
  height: 49px;
  background-color: #ebc03f;
}

.activeOption::before {
  left: 0;
}

.activeOption::after {
  right: 0;
}

.inactiveOption {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 23px; 
  margin-bottom: 25px;
  height: 49px;
}

@media (max-width: 768px) {
  .activeOption{
    padding-left: 20px
  }
  .inactiveOption{
    padding-left: 20px
  }
}