.container {
  display: flex;
  align-items: center;
}

.dropdown {
  border-radius: 4px;
  font-size: 16px;
  border: 1px #5e5e5e;
  margin-right: 5px;
  cursor: pointer;
  background-color: white;
  height: 48px !important;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: black !important;
  min-width: 110px;
  width: auto;
}

.phoneNumberInput {
  border:1px solid #5E5E5E;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px 10px;
  background-color: white;
  height: 48px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #2F2F2F !important;
  width: 100%;
}

.phoneNumberInput:focus {
  /* Override focus border color to stay black */
  border-color: #5E5E5E;
  outline: 1px solid #5E5E5E; 
}

.phoneNumberInput::placeholder { /* Most modern browsers support this now. */
  color:#A2A2A2 !important;
}

.inputError {
  border: 1px solid rgba(227, 74, 71, 1);
}

  /* Firefox specific style */
  @-moz-document url-prefix() {
    .phoneNumberInput::placeholder {
      color: #4e4e4e !important;
    }
  }

.disabledInputStyle {
  background-color: hsl(0, 0%, 95%);
}

@media (max-width: 768px) {
  .dropdown {
    height: 40px !important;
  }
  .phoneNumberInput {
    height: 40px;
  }
}

@media (max-width: 600px) {
  .dropdown {
    width: auto;
    min-width: 90px;
  }
  .phoneNumberInput::placeholder { /* Most modern browsers support this now. */
    font-family: "Brown", sans-serif;
    font-weight: 400;
    color:#a2a2a2 !important;
    font-size: 14px; 
  }
}

/* PhoneNumberValidation.module.css */
.placeholderColor::placeholder {
  color: grey !important;
  /* Add other styles like opacity or font-weight if needed */
}