  
  .headerSection {
    background-color: #fcf8e8;
    border-radius: 41px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 15px 0;
    padding: 15px 20px;
  }
  
  .title {
    font-family: "Brown", sans-serif;
    font-weight: 400;
    color: #5e5e5e;
    font-size: 20px;
    line-height: 15.4px;
  }
  
  .controls {
    display: flex;
    align-items: center;
    /* If you want more space between the controls, you can adjust the margin here */
  }
    
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 1000; /* Ensure it covers other elements */
  }
  
  .searchInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A2A2A2;
    padding-left: 0px;
    font-family: "Brown", sans-serif;
    font-weight: 400;
  }
  
  .searchInput:focus-visible {
    outline: none;
  }
  
  .filterSection {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;
    width: 108px;
    border-radius: 30px;
    background-color: #fff;
    justify-content: center;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    color: #868686;
  }
  
  .filterIcon {
    margin-right: 10px; /* Adjust the spacing between icon and text as needed */
  }
  
  .createUserButton {
    width: 9.5rem;
    border-radius: 24px;
    color: #2f2f2f;
    background-color: #e8ba49;
    font-family: "Brown", sans-serif;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
  }

  .filterSection {
    position: relative;
    /* Other styles... */
  }
    
  .filterDropdown {
    position: absolute;
    top: 100%; /* Position the dropdown below the filter section */
    left: 0;
    right: 10px; /* Adjust this value to push the dropdown to the left */
    transform: translateX(
      -10%
    ); /* This will move it left by 10% of the dropdown width */
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 10; /* Ensure it's above other elements */
    width: 250px; /* Adjust width as necessary */
    display: flex;
    flex-direction: column;
  }
  
  .filterContentWrapper {
    margin: 15px;
    width: 220px;
  }
  
  .filterHeader {
    font-size: 16px;
    margin-bottom: 10px;
    color: #868686;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    margin-left: 3px;
  }
  
  .filterCategory {
    margin-bottom: 15px;
  }
  
  .filterTitle {
    font-size: 18px;
    margin-bottom: 5px;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    color: #2f2f2f;
  }
  
  .filterOption {
    display: flex;
    align-items: center;
  }
  
  .filterOption label {
    cursor: pointer; /* Show pointer on hover over the label */
    font-size: 16px;
    color: #5e5e5e;
    font-family: "Brown", sans-serif;
    font-weight: 400;
  }
  
  .applyFilterButton {
    background-color: rgba(94, 94, 94, 1); /* Use your primary color here */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 0 0 12px 12px;
    cursor: pointer;
    text-align: center;
    display: block;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    margin: 0;
    font-size: 18px;
    line-height: 13px;
    font-weight: 400;
    font-family: "Brown", sans-serif;
    font-size: 18px;
    line-height: 13.86px;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    /* No margin-top here, so the button sits directly below the filter options */
  }
  
  .applyFilterButton:hover:not(:disabled) {
    background-color: #ebc03f;
    color: #fff;
  }
  
  .applyFilterButton:disabled {
    cursor: not-allowed;
  }
  
  /* This ensures the filter dropdown does not close when clicking inside it */
  .filterDropdown > * {
    pointer-events: auto;
  }
  
  .filterSection > * {
    pointer-events: none;
  }
  
  .filterSection {
    pointer-events: auto;
  }
  .errorText {
    color: #e34a47;
    font-size: 12px;
    margin-top: 5px;
    text-align: left;
    margin-top: 10px;
    font-family: "Brown", sans-serif;
  }
  .ageBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 80%;
    margin-left: 8px;
  }
  
  .fromLabel,
  .toLabel {
    color: #686868;
    font-size: 14px;
    font-family: "Brown", sans-serif;
  }
    
  @media (max-width: 768px) {
    .userDetailsTable {
      width: 100%;
      font-family: Arial, sans-serif;
      background-color: #fff;
      overflow-x: hidden; /* Enables horizontal scrolling */
      overflow-y: hidden; /* Prevents vertical scrolling */
    }
    .headerSection {
      display: none;
    }
    .userDetailsContainer {
      background-color: #fff !important;
    }
  }
  