.statusSection {
  background-color: #fff;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 69px;
  margin-right: 10px;
  overflow-y: auto;
  flex: 0 1 69px;
  transition: flex-basis 0.5s ease-in-out;
}
.statusSection:hover {
  flex-basis: 244px !important;
}

.iconWithLabel {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.iconLabel {
  font-size: 16px;
  color: #686868;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  transition: width 0.5s ease-in-out;
}

.statusSection:hover .iconLabel {
  width: auto;
  padding-left: 8px;
}
.logOutIcon {
  align-self: flex-end;
  margin-top: 190px;
}
.activeIconLabel {
  font-size: 16px;
  color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  transition: width 0.5s ease-in-out;
}

.statusSection:hover .activeIconLabel {
  width: auto;
  padding-left: 8px;
}
@media (max-width: 769px) {
  .statusSection {
    width: 100%;
    box-shadow: none;
    background: #fcf8e8;
    margin-top: 40px;
  }

  .iconWithLabel {
    margin-bottom: 40px;
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  .activeHomeIcon {
    width: 30px;
    height: 30px;
  }
  .homeIcon {
    width: 30px;
    height: 30px;
  }
  .activeIconLabel {
    color: #ebc03f;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 20px;
    white-space: nowrap;
    width: auto;
    padding-left: 8px;
    text-transform: none !important;
  }
  .iconLabel {
    font-size: 16px;
    color: #686868;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    white-space: nowrap;
    width: auto;
    padding-left: 8px;
    text-transform: none !important;
  }
  .logOutIcon {
    position: absolute;
    bottom: 5px;
  }
  .createUserButton {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
    width: 90%;
    border-radius: 24px;
    color: #2f2f2f;
    background-color: #ebc03f;
    font-family: "Brown", sans-serif;
    font-weight: 700;
    position: relative;
    font-size: 18px;
    word-spacing: 2px;
  }
}
