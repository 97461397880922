.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.formContainer {
  display: flex;
  align-items: center;
  gap: -5px; 
  width: 180px;
  margin-right: -15px;
}

.navbarHeading {
  margin: 0;
  margin-right: 20px;
  color: #5e5e5e;
  font-size: 20px;
  font-weight: 400;
  font-family: "Brown", sans-serif;
}

.dropdown.css-b62m3t-container {
  width: 100%; 
  max-width: 400px; 
  background-color: #FFFFFF;
  height: 45px; 
 
}

.vido {
  margin-left: 5px !important;
  background-color: #EBC03F !important;
  cursor: pointer;
  width: 160px; 
  color: #363636!important;
  height: 42px !important;
  text-transform: none !important;
  align-items: center;
  margin-top:-5px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  
}

.vido:disabled {
  background-color: #a2a2a2 !important;
  
}

.videoText {
  font-family: "Brown", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 10.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

@media (max-width: 800px) {
  .navbarContainer {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .navbarHeading {
    font-size: 18px;
  }

  .formContainer {
    width: 100%; 
    flex-direction: column;
    align-items: flex-start;
  }

  .dropdown.css-b62m3t-container,
  .vido {
    width: 100%;
    margin-top: 10px;
  }
}

