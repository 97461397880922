.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modalContent {
  background: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 5px;
  border-radius: 18px;
  z-index: 11;
  text-align: center;
  width: 533px;
  height: auto;
  /* Further styling as needed */
}

.modalTitle {
  color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin: 30px auto;
  margin-top: 0;
}

.modalInfo {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #5e5e5e;
  margin-top: 10px;
}

.modalActions {
  margin: 39px auto 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modalCancel {
  background: none;
  border: none;
  width: 60px;
  height: 40px;
  border-radius: 43px;
  color: #363636;
  width: 87px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Brown", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.modalCancel:hover {
  background-color: #E5E5E5;
}

.modalConfirm {
  background-color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  border-radius: 43px;
  color: #363636;
  width: 144px;
  height: 40px;
  font-size: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.modalConfirm:hover {
  background-color: #d1a635;
}

.modalConfirm:disabled {
  background-color: #c9c7c7;
  opacity: 0.5;
  cursor: not-allowed;
}

.modalCancel:disabled {
  background-color: white;
  cursor: not-allowed;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin: 5px 0 10px;
}

.label {
  font-family: "Brown", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #5e5e5e;
  margin-bottom: 5px;
  text-align: left;
}

.modalInput,
.modalSelect {
  padding: 10px;
  font-family: "Brown", sans-serif;
  font-size: 16px;
  border: 1px solid #868686;
  border-radius: 4px;
  margin: 0;
  border-color: #5E5E5E;
}

.modalInput:focus {
  /* Override focus border color to stay black */
  border-color: #5E5E5E;
  outline: 1px solid #5E5E5E; 
}

.customSelect {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  text-align: left;
  height: 48px;
}

.dropdown {
  position: absolute;
  background-color: #fff;
  width: 284px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border-radius: 4px;
  overflow: hidden;
  text-align: start;
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdownItem:hover {
  background-color: #f2f2f2;
}
.dropDownAlign {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-family: "Brown", sans-serif;
}
.usernameError,
.emailError,
.roleError {
  color: #e34a47;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
  margin-top: 10px;
  font-family: "Brown", sans-serif;
}
.placeHolderField {
  color: rgba(134, 134, 134, 1);
  font-family: "Brown", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.modalInput::placeholder {
  color: rgba(134, 134, 134, 1) in !important;
  opacity: 1;
}
.inputError {
  border: 1px solid rgba(227, 74, 71, 1);
}
.iconTextWrapper {
  display: flex;
  align-items: center;
}

.successText {
  font-family: "Brown", sans-serif;
  font-size: 16px;
  color: #016f4a;
  padding-left: 5px;
}

.errText {
  font-family: "Brown", sans-serif;
  font-size: 16px;
  color: #e34a47;
}
.responseTextBox {
  margin: 10px auto;
}
.modalPhoneInput {
  text-align: left;
  padding: 10px;
  font-family: "Brown", sans-serif;
  font-size: 16px;
  border: 1px solid #868686;
  border-radius: 4px;
  margin: 0;
  width: 100%;
  color: #686868 !important;
}
.disabledField {
  background-color: #f9f9f9 !important;
  border: 1px solid #e5e5e5 !important;
  padding-left: 15px;
  color: #686868;
  /* Add other styling as needed for the disabled state */
}

@media (max-width: 600px) {
  .modalContent {
    width: 95%;
    margin: auto;
  }
}
