/* SimulationDuration.module.css */
.customInputContainer {
  position: relative;
  display: flex; 
  flex-grow: 1;
  width: 100%;
}
  
.inputField{
  width: 100%;
  height: 44px;
  padding-right: 30px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding-left: 10px;
  cursor: pointer;
  color: #1D1F22;
  font-size: 14px;
  font-family: "Brown", sans-serif;
}

  .largeInputContainer {
    position: relative;
    display: inline-flex;
    width: 100%;
  }
  
  .largeinputField {
    width: 95%;
    height: 44px;
    padding-right: 30px; 
    border: 1px solid #E5E5E5; 
    border-radius: 4px; 
    padding-left: 10px; 
    cursor: pointer;
    color: #1D1F22;
    font-size: 14px;
    font-family: "Brown", sans-serif;
  }

  .inputField:focus {
    outline: none; 
  }

  .largeinputField:focus {
    outline: none; 
  }

  .inputField::placeholder {
    color: #A2A2A2; 
  }

  .largeinputField::placeholder {
    color: #A2A2A2; 
  }

  .icon {
    position: absolute;
    right: 4%; 
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent icon from blocking input */
  }
  
  .largeicon {
    position: absolute;
    right: 9.5%; 
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent icon from blocking input */
  }
  
  .icon svg {
    width: 20px; 
    height: 20px; 
  }
  
  .submitted {
    background-color: #F9F9F9;
    border-color: #E5E5E5;
    cursor: not-allowed;
  }