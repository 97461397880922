.labelForMobileAndEdit {
  display: flex;
  justify-content: space-between;
}

.mobileNumberLabelEditIcon {
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #363636;
  font-size: 16px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  cursor: pointer;
  color: #ebc03f;
}
.mobileNumberLabelEditIcon:hover {
  color: #d1a635;
}

.container {
  display: flex;
  align-items: start;
}

.dropdown {
  font-size: 16px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  border-color: #868686 !important;
  margin-right: 5px;
  min-width: 95px;
  border-radius:4px;
  background-color: #f9f9f9;
}

.dropdown:disabled {
  color: #f9f9f9;
  border: none;
  background-color: #fff;
}

.phoneNumberInput {
  border: 1px solid #5E5E5E;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px 10px;
  background-color: white;
  height: 48px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #2F2F2F !important;
  width: 100%;
  color: #363636 !important;

}

.phoneNumberInput:focus {
  border-color: #5E5E5E;
  outline: 1px solid #5E5E5E; 
 }

.phoneNumberInput:disabled {
 color: #686868 !important;
 background-color: #f9f9f9;
 border: 1px solid #e5e5e5;
}

/* Firefox specific style */
@-moz-document url-prefix() {
  .phoneNumberInput::placeholder {
    color: #4e4d4d !important;
  }
}

.disabledInputStyle {
  background-color: hsl(0, 0%, 95%);
}

.error {
  color: #e34a47;
  margin-top: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
  display: flex;
}

.inputError {
  border: 1px solid rgba(227, 74, 71, 1);
}

@media (max-width: 600px) {
  .dropdown {
    width: 50%;
    min-width: 100px;
  }
}
