.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 18px;
  z-index: 11;
  text-align: center;
  width: 533px;
  height: 200px;
}

 .modalTitle{
  color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin: 4px auto 16px auto;
 }

 .modalInfo{
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #5E5E5E;
  margin-top: 10px;
 }

.modalActions {
  margin: 42px 26px 26px auto;
  display: flex;
  justify-content: flex-end;
}

.modalCancel{
  background : none;
  border: none;
  width: 130px;
  color: #363636;
  font-size: 16px;
  font-family: "Brown", sans-serif;
  border-radius: 43px;
  -webkit-tap-highlight-color: transparent;
}

.modalCancel:hover {
  background-color: #E5E5E5;
}
.modalConfirm{
 background-color: #ebc03f;
 border-radius: 43px;
 color: #363636;
 width: 144px;
 font-size: 16px;
 font-family: "Brown", sans-serif;
 -webkit-tap-highlight-color: transparent;
}

.modalConfirm:hover{
  background-color: #d1a635;;
}
@media (max-width: 600px) {
  .modalActions{
    margin: 18px auto;
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
  }
  .modalTitle{
    font-size: 16px;
    margin: 0px;
    margin-bottom: 10px;
  }
  .modalContent {
    background: white;
    padding: 3% 4% 3% 4%;
    border-radius: 12px;
    z-index: 11;
    text-align: center;
    margin-left: 8%;
    margin-right: 8%;
    height: 150px;
  }
  .modalConfirm {
    height: 40px;
    width: 122px;
  }
  .modalCancel:hover{
    background-color: #fff;;
  }
  .modalCancel{
    height: 40px;
    width: 92px;
  }
  .modalInfo {
    font-size: 14px;
    width: 14px;
  }
  .modalInfoContainer {
    padding-left: 19%;
    padding-right: 19%;
  }
}

@media (min-width: 601px) and (max-width: 768px){
  .modalActions{
    margin: 18px auto;
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
  }
  .modalTitle{
    font-size: 16px;
    margin: 0px;
    margin-bottom: 10px;
  }
  .modalContent {
    background: white;
    padding: 3% 4% 3% 4%;
    border-radius: 12px;
    z-index: 11;
    text-align: center;
    width: 380px;
    margin-left: 8%;
    margin-right: 8%;
    height: 150px;
  }
  .modalConfirm {
    height: 40px;
    width: 122px;
  }
  .modalCancel:hover{
    background-color: #fff;;
  }
  .modalCancel{
    height: 40px;
    width: 92px;
  }
  .modalInfo {
    font-size: 14px;
    width: 14px;
  }
}