*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dashboardContainer{
    background-color: #fff;
    height: 100vh;
}
