* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.welcomeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
  position: relative;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlayText {
  margin-left: 2.5rem;
  color: #fff;
  font-family: "Brown", sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  max-width: 90%;
  z-index: 1;
  line-height: 43.2px;
}

.overlayText > h1 {
  font-size: 36px;
}

.logo {
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  width: 65.33px;
  height: 50px;
  z-index: 10;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.welcomeText {
  max-width: 58%;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  line-height: 18.28px;
}
.welcomeText > p,
button {
  margin-left: 0.3rem;
}
.proceedButton {
  display: flex;
  align-items: center !important;
  justify-content: center;
  padding: 10px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  font-size: 16px;
  border-radius: 20px;
  background-color: #f9f7f1;
  color: #3b3830;
  margin-top: 24px;
  border-radius: 20px;
  width: 160px;
  -webkit-tap-highlight-color: transparent;
}

.proceedButton:hover {
  background-color: #ebc03f;
}

.greaterThan {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-bottom: 3px;
}

.greaterThan img {
  height: auto;
  width: auto;
  position: relative;
  top: 2px;
  left:2px;
}

.greaterIcon {
  pointer-events: none;
  user-select: none;
}

.adminLink {
  position: absolute;
  bottom: 50px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .overlayText,
  .welcomeText {
    margin: 0;
    max-width: 100%;
    text-align: center;
    padding: 0 10px;
  }
  .welcomeContainer{
    overflow: hidden !important;
  }
  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .welcomeText > p,
  .proceedButton {
    margin-left: auto;
    margin-right: auto;
  }

  .proceedButton {
    width: auto;
    padding: 10px 20px;
    margin-top: 3rem;
  }
  .welcomeContainer {
    align-items: center;
    overflow: hidden !important;
  }
  .adminLink {
    position: absolute;
    bottom: 85px;
    cursor: pointer;
    margin-left: 0 !important;
    left: 0;
    right: 0;
  }
  .adminLinkText {
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-decoration: underline;
    color: #ffffff;
  }
  .logo {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65.33px;
    height: 50px;
    z-index: 10;
  }
}

.btnProceed {
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminLinkText {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
  color: #ffffff;
}
