/* SignInScreen.module.css */
.selectPlaceholderText {
  color: #A2A2A2
}

.container {
  display: flex;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url("../../../assets/images/pattern-background.png");
}

.imageSection {
  flex: 3;
  background-size: cover;
  background-position: center;
}

.logo {
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  width: 65.33px;
  height: 50px;
  z-index: 10;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.signInSection {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin-top: -4rem;
  background-image: url("../../../assets/images/pattern-background.png");
}

.signInSectionForRdp{
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background-image: url("../../../assets/images/pattern-background.png");
}

.signInBox {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.signInBoxSuccess {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.signinTitle {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 3.7rem;
  color: #363636;
  font-size: 32px;
}

.signInHeading {
  color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 5px;
}

.inputGroup {
  margin-bottom: 20px;
}
form input:disabled{
background-color: #f9f9f9 !important;
border: 1px solid #E5E5E5;
color: #686868;
}

.flexRow {
  display: flex;
}

.countryCode {
  width: 18%;
  margin-right: 4%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
  color: #363636;
}

.mobileNumber {
  width: 75%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
  color: #363636;
}

.mobileNumberLabel {
  display: block;
  margin-top: 13px;
  margin-bottom: 10px;
  color: #363636;
  font-size: 16px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

button {
  width: 40%;
  padding: 10px;
  background-color: #e5e5e5;
  color: #a8a8a8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.otpButtonInactive {
  background-color: #e5e5e5;
  color: #a8a8a8;

  cursor: not-allowed;
  border: none;
  border-radius: 20px;
  font-family: "Brown", sans-serif;
  font-weight: 500;
}

.customFormControl {
  border: 0.8px solid #5e5e5e !important;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  margin-left: 47px !important;
  padding: 10px !important;
  width: 90% !important;
}
.selected-flag {
  border: 1px solid #5e5e5e;
}

.labelForMobileAndEdit {
  display: flex;
  justify-content: space-between;
}

.mobileNumberLabelEditIcon {
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #363636;
  font-size: 16px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  cursor: pointer;
  color: #ebc03f;
}
.mobileNumberLabelEditIcon:hover {
  color: #d1a635;
}

.phoneNumberInput {
  font-family: "Brown", sans-serif;
  font-weight: 500;
}

.downArrowIcon {
  position: relative;
  top: 30px;
  bottom: 30px;
  left: 23px;
  z-index: 10;
}

.otpButton {
  background-color: #E5E5E5;
  color: #A2A2A2;
  cursor: not-allowed;
  border: none;
  margin-left: -1.5px;
  border-radius: 20px;
  width: 50%;
  margin-top: 36px;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  font-size: 16px;
  width: 130px;
  justify-content: center;
  display: flex;
}

.otpButtonActive {
  background-color: #ebc03f;
  color: #363636;
  cursor: pointer;
}

.otpButtonActive:hover {
  background-color: #d1a635;
}

.error {
  color: #ED6464;
  margin-top: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.errorInp {
  background-color: #f18a89;
}

.errorInput {
  background-color: #fbece9;
  border: 1px solid #f5b9b7;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    overflow: auto;
  }

  .imageSection {
    flex: none;
    width: 100%;
    height: 40vh;
  }

  .signInSection {
    display: flex;
    justify-content: center;
    align-items: normal;
  }

  .signInSectionForRdp{
    margin-top: 0;
  }

  .signinTitle {
    font-family: "Brown", sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    margin-bottom: 1rem;
    color: #363636;
    font-size: 20px;
  }

  .selectPlaceholderText {
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  .signInSection {
    display: flex;
    justify-content: center;
    background: #fff;
    margin-top: 0px;
  }

  .signInHeading {
    margin: 0;
    font-size: 16px;
  }

  .buttonStyle {
    margin-top: 0px; /* Add some space above the button */
    -webkit-tap-highlight-color: transparent;
  }
  .mobileNumberLabel {
    margin-top: 10px;
    font-size: 14px;
  }
  .otpButton {
    width: 100%;
  }
  .otpButtonActive{
    width: 100%;
  }

  .logo {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 44px;
    height: 33.67px;
    z-index: 10;
  }
  .signInBox {
    padding: 15px;
  }
}

.formControl {
  background-color: #fbece9 !important;
  margin-left: 56px !important;
}

.countryList .flag {
  display: none;
}

.countryList .country-name {
  display: none;
}

.countryList .dial-code {
  padding-left: 8px;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 10px;
  margin-left: 47px !important;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 300px;
  outline: none;
}
