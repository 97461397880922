.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(46, 46, 46, 0.33);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 18px;
  z-index: 11;
  text-align: center;
  width: 533px;
  padding-left: 55px;
  padding-right: 55px;
}

.modalTitle {
  color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin: 15px auto;
  margin-top: 0;
}

.modalInfo {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #5e5e5e;
  margin-top: 10px;
  line-height: 20px;
  letter-spacing: 0px;
}

.modalActions {
  margin-top: 27px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 7px !important;
  display: flex;
  justify-content: center;
}

.modalCancel {
  background: none;
  border: none;
  width: 130px;
  color: #363636;
  font-size: 16px;
}
.modalConfirm {
  background-color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  border-radius: 43px;
  color: #363636;
  width: 144px;
  font-size: 16px;
}

.modalConfirm:hover {
  background-color: #d1a635;
}

@media (max-width: 768px) {
  .modalActions {
    margin-top: 4% !important;
    display: flex;
    justify-content: center;
  }
  .modalTitle{
    font-size: 16px;
    margin: 0px;
    margin-bottom: 10px;
  }
  .modalContent {
    background: white;
    padding: 3%;
    border-radius: 12px;
    z-index: 11;
    text-align: center;
    width: 533px;
    margin-left: 8%;
    margin-right: 8%;
    height: 150px;
    /* Further styling as needed */
  }
  .modalConfirm {
    height: 40px;
    width: 122px;
  }
  .modalCancel{
    height: 40px;
    width: 122px;
  }
  .modalInfo {
    font-size: 14px;
    width: 14px;
  }
  .modalInfoContainer {
    padding-left: 5%;
    padding-right: 5%;
  }
}
