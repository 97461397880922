.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}

.modalContent {
  background: white;
  border-radius: 5px;
  z-index: 11;
  text-align: center;
  width: 95%;
  height: auto;
  /* Further styling as needed */
  position: relative; /* Add this line */
}

.modalTitle {
  color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin: 15px auto;
}

.modalInfo {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #5e5e5e;
  margin-top: 10px;
}

.modalActions {
  margin: 14px auto;
  display: flex;
  justify-content: center;
}

.modalCancel {
  background: none;
  border: none;
  width: 130px;
  height: 40px;
  color: #363636;
  font-size: 16px;
}
.modalConfirm {
  background-color: #ebc03f;
  border-radius: 43px;
  color: #363636;
  width: 144px;
  height: 40px;
  font-size: 16px;
}

.profileInfoContainer {
  width: 100%;
}
@media (max-width: 768px) {
  .profileIconWrapper {
    display: flex; /* Align children in a row */
    align-items: center; 
  }
  .backgroundForProfileIcon {
    /* Ensure this container is also flex to center the image inside it */
    background-color: #ebc03f;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Other styles remain unchanged */
    height: 150px;
    width: 50px;
    border-top-left-radius: 5px;
  }
  .backgroundForProfileIconImage {
    margin: 17px auto;
  }
  .statusWrapperContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .statusTextWrapper {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Adjust spacing between icon and text as needed */
    /* Other styles remain unchanged */
  }

  .statusTextWrapper:not(:last-child) {
    margin-bottom: 15px; /* Adjust the gap size as needed */
  }
  .statusIcon {
    width: 24px; /* Set a fixed width */
    height: 24px; /* Set a fixed height */
    /* Other styles remain unchanged */
  }
  .inprogress,
  .verified,
  .inprogressText, 
  .unTouchedText {
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-left: 10px;
  }
  .verticalLine {
    width: 1px; /* Width of the line */
    background-color: #686868; /* Color of the line */
    height: 23px; /* Height of the line, adjust as needed */
    /* Add margins if you want to increase the spacing around the line */
    margin-left: 21px;
    margin-bottom: 10px;
  }
  .vrIconWrapper {
    display: flex; /* Align children in a row */
    align-items: center; /* Center children vertically */
  }
  .backgroundForVrIcon {
    /* Ensure this container is also flex to center the image inside it */
    background-color: #ebc03f;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Other styles remain unchanged */
    border-bottom-left-radius: 5px;
    height: 150px;
    width: 50px;
  }
  .backgroundForProfileIconImage {
    margin: 17px auto;
  }

  .backgroundForProfileIconImageVr {
    margin: 21px auto;
  }
  .horizontalLine {
    height: 0.5px; /* Line thickness */
    background-color: #e5e5e5; /* Line color, adjust as needed */
    width: 100%; /* Line width */
    margin: 0.5px 0; /* Spacing above and below the line */
  }
  .closeIconWrapper {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .inprogressText {
    color: #b89536;
  }
  .inprogress {
    height: 24px;
    width: 24px;
    border: 1px solid #b89536;
    border-radius: 60%;
    background-color: transparent;
    display: flex; /* Align children using Flexbox */
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Center children vertically */
    font-size: 14px; 
    margin-left: auto;
  }
  .inProgresslabelText {
    text-align: center;
    color: #b89536;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    /* Remove margin or set it to 0 */
  }

  .untouchedProgressIcon {
    height: 24px;
    width: 24px;
    border: 0.5px solid #868686;
    border-radius: 60%;
    background-color: transparent;
    display: flex; /* Align children using Flexbox */
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Center children vertically */
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }

  .unTouchedText {
    color: #5e5e5e;
  }

  .unTouchedLabelText {
    text-align: center;
    color: #5e5e5e;
    font-family: "Brown", sans-serif;
    font-weight: 400;
  }
  .verified {
    color: #016f4a;
  }
}
