/* VisualizationTable.module.css */

/* Dropdown select styles */
.dropdownSelect {
  width: 100%;
  padding: 8px;
  font-family: "Brown", sans-serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #2F2F2F;
}

.dropdownSelect:focus {
  outline: none;
  border-color: #EBC03F;
}

/* Table container styles */
.tableContainer {
  width: 100%;
  box-shadow: none;
  margin-bottom: 280px;
}

.table th {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  background-color: #ffffff;
  color: #2F2F2F;
  font-size: 16px;
}

.table td {
  font-family: "Brown", sans-serif;
  color: #5E5E5E;
  font-size: 14px;
}

.boldText {
  font-weight: bold;
  font-family: "Brown", sans-serif;
  font-size: 16px;
}

.link {
  color: rgb(235, 192, 63) !important;
  cursor: pointer;
  text-decoration: underline !important;
}


.formContainer {
  min-height: 90vh;
  background-color: #fff;
  width: 100%;
  margin: auto;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
