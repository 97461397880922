.statusSection {
  background-color: #fff;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 69px;
  margin-right: 10px;
  overflow-y: auto;
  flex: 0 1 69px;
  transition: flex-basis 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will push the logout icon to the bottom */
  position: relative;
  overflow: hidden;
}
.statusSection:hover {
  flex-basis: 244px !important;
}

.iconWithLabel {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 35px;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.iconLabel {
  font-size: 16px;
  color: #a2a2a2;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  transition: width 0.5s ease-in-out;
}
.logOutIconLabel {
  color: #5e5e5e;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-left: 8px;
}

.statusSection:hover .iconLabel {
  width: auto;
  padding-left: 8px;
}
.logOutIcon {
  margin-top: auto; /* This will push the logout icon to the bottom */
  position: absolute; /* Absolute position the logout to the bottom */
  bottom: 20px; /* Space from the bottom */
  left: 0; /* Align to the left */
  width: 100%;
}
.activeIconLabel {
  font-size: 16px;
  color: #ebc03f;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  transition: width 0.5s ease-in-out;
}

.statusSection:hover .activeIconLabel {
  width: auto;
  padding-left: 8px;
}
@media (max-width: 768px) {
  .statusSection {
    width: 80%;
    box-shadow: none;
    background: #fcf8e8;
    margin-top: 40px;
  }

  .iconWithLabel {
    margin-bottom: 40px;
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  .activeHomeIcon {
    width: 30px;
    height: 35px;
  }
  .homeIcon {
    width: 30px;
    height: 25px;
  }
  .activeIconLabel {
    color: #ebc03f;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 20px;
    white-space: nowrap;
    width: auto;
    padding-left: 8px;
  }
  .iconLabel {
    font-size: 20px;
    color: #a2a2a2;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    white-space: nowrap;
    width: auto;
    padding-left: 8px;
  }
  .logOutIcon {
    position: absolute;
    bottom: 50px !important;
    left: 0;
    width: 100%;
  }
  .logOutIconLabel{
    overflow: visible !important;
  }
  .statusSection .logOutIconLabel {
    font-size: 20px;
    color: #5E5E5E;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    white-space: nowrap;
    width: auto;
    padding-left: 8px;
  }
  
}
.logOutIconLabel {
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.statusSection:hover .logOutIconLabel {
  width: auto;
  padding-left: 8px;
}

.logOutIcon {
  margin-top: auto;
  position: absolute;
  bottom: 0px;
  left: 20px;
  right: 20px;
}

.logOutIconLabel {
  padding-left: 0;
}

.statusSection .logOutIconLabel {
  width: 0;
}

.iconWithLabel:hover .iconLabel,
.iconWithLabel:hover .activeIconLabel {
  width: auto;
}
