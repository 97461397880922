.updateProfileContainer {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
}
.profileTitle {
  font-size: 24px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #b89536;
}
.formGroup {
  display: flex;
  flex-direction: column;
  width: 467px;
}
.errorMsg {
  color: #e34a47;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 3px;
}

.firstNameField {
  height: 48px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #686868;
}

.firstNameField:focus {
  /* Override focus border color to stay black */
  border-color: #5E5E5E;
  outline: 1px solid #5E5E5E; 
}

.firstNameLabel {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 5px;
  color: #363636;
}

.formRow {
  display: flex;
  width: 467px;
}
.ageInput {
  width: 165px;
  height: 48px;
  border: 0.5px solid #272727;
  border-radius: 4px;
  padding: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

 /* Firefox specific style */
 @-moz-document url-prefix() {
  .ageInput::placeholder {
    color: #525252 !important;
  }
}

.ageInput:focus {
  /* Override focus border color to stay black */
  border-color: #5E5E5E;
  outline: 1px solid #5E5E5E; 
}

.genderField {
  width: 300px;
  height: 48px;
  border-radius: 4px;
  border: 0.5px solid #272727;
  padding: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.ageInput::placeholder {
  color: #a2a2a2;
}
.genderField::placeholder {
  color: #a2a2a2 !important;
}

.selectTag {
  color: #a2a2a2 !important;
}

.ageLabel,
.genderLabel {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 5px;
  color: #363636;
}
.saveChangesButton {
  width: 236px;
  height: 47px;
  border-radius: 29px;
  color: #fff;
  background-color: #939393;
  margin-top: 25px;
  margin-left: 0;
}
.saveChangesButton:hover {
  background-color: #d1a635;
}

.updateProfileContainer {
  display: flex;
  flex-direction: column;
  width: 100%; /* Changed from 500px to be 100% */
  max-width: 500px; /* Keeps the original max width but allows for smaller sizes */
}

.formGroup,
.formRow {
  display: flex;
  width: 100%; /* Makes it responsive */
}

.formRow {
  display: flex;
  justify-content: space-between; /* Distributes space between and around content items */
  width: 100%; /* Full width to accommodate inner flex items */
}

.ageInput {
  /* Adjusted widths to ensure they fit in a single row on smaller screens */
  margin-right: 0px; /* Adds some spacing between the fields */
}

/* Last child in .formRow should not have a margin-right to align properly */
.formRow > :last-child {
  margin-right: 0;
}

.dropdownStyle {
  border-radius: 10px !important;
  min-width: 284px !important;
  margin-top: 7px !important;
  margin-left: 7px !important;
  box-shadow: 0px 2px 5.9px 0px #0000001A !important;
}

.otpButtonActive {
  background-color: #ebc03f;
  color: #363636;
  cursor: pointer;
  border: none;
  margin-left: -1.5px;
  border-radius: 29px;
  margin-top: 45px;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  width: 236px;
  height: 47px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .firstNameLabel,
  .ageLabel,
  .genderLabel {
    font-size: 20px; /* Adjust font size */
  }
  .formRow {
    flex-direction: row; /* Stack fields vertically */
  }
  .firstNameLabel{
    margin-top: 0px;
  }
  .ageInput,
  .genderField,
  .saveChangesButton {
    width: 100%; /* Full width for smaller screens */
    margin-top: 3px; /* Adjust spacing */
    margin-right: 0px;
    width: 95%;
  }
  .selectField {
    width: 80%;
  }
  .formRow {
    flex-wrap: nowrap;
  }
  .ageInput{
    margin-top: 0;
  }
  .firstNameLabel,
  .genderLabel,
  .ageLabel {
    font-size: 14px;
    font-weight: 400;
    color: #686868;
  }
  .genderField {
    margin-left: 15px;
  }
  .buttonStyle {
    display: flex;
    width: 132px;
    align-items: center; /* Center button vertically if needed */
  }
  .dropdownStyle {
    min-width: 41% !important;
  }
  .errorMsg {
    color: #e34a47;
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 12px;
    margin-top: 3px;
  }
  .profileTitle{
    display: none;
  }
  .otpButtonActive{
    height: 40px;
    margin-top: 30px;
  }
}



.otpButtonActive:hover {
  background-color: #d1a635;
}

.otpButtonDisabled {
  background-color: #e5e5e5 !important;
  cursor: not-allowed;
  color: #a2a2a2;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}
.selectField {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  padding: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
  background: white; /* You might need this for consistent background */
  color: #272727; /* Default text color */
  margin: 0 !important;
  border: 0.5px solid #272727 !important;
}
.selectField:focus {
  /* Override focus border color to stay black */
  border-color: #5E5E5E;
  outline: 1px solid #5E5E5E; 
}
.selectTextDrodpwn {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px !important;
  margin-top: 16px;
  color: #a2a2a2;
}
.overlay {
  position: fixed; /* or absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed background color */
  z-index: 1000; /* Make sure it's above other content */
}
.errorInput {
  border: 0.5px solid #e34a47 !important;
  color: #e34a47;
  background-color: rgba(227, 74, 71, 0.1);
}
.disabledInput {
  border: 0.5px solid #E5E5E5 !important;
  color: #E5E5E5;
  background-color: #F9F9F9;
}
.disabledField {
  background-color: #f9f9f9 !important;
  color: #686868;
}
.selectPlaceholder {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px !important;
  color: #a2a2a2;
}

.menuItemLabel {
  font-family: "Brown", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #363636 !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}