.participantProfileCreation {
  flex: 2;
  justify-content: center;
  align-items: center;
  background: #fcfbf9;
  display: flex;
  background-image: url("../../../../assets/images/pattern-background.png");
}

.profileContainer {
  max-width: 100%;
  text-align: center;
}

.profileName {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  text-align: center;
  color: #2f2f2f;
  margin-bottom: 5rem;
}

.profileText {
  font-family: "Brown";
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 20px;
  color: #016f4a;
}

.profileCreation {
  margin-bottom: 3rem;
  max-width: 40vh;
  margin: auto;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

.button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;

  background: none;
  width: auto;
}

.agreeButton {
  background-color: #ebc03f;
  color: #16181b;
  border-radius: 29px;
  width: 236px;
  height: 47px;
  font-family: "Brown", sans-serif;
  font-weight: 500;
}

@media (max-width: 768px) {
  .profileContainer {
    max-width: 100%;
    text-align: center;
  }

  .profileName {
    margin-bottom: 10px;
    text-align: center;
    margin-left: 7px;
    display: none;
  }

  .participantProfileCreation {
    display: block;
    margin-top: 2rem;
  }

  .consentContent {
    max-width: 100vh;
  }

  .buttonContainer {
    justify-content: center;
    flex-wrap: wrap;
  }

  .button,
  .agreeButton,
  .declineButton {
    flex: 1;
    margin: 0 5px;
    text-align: center;
  }
}
