.otpContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.otpTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #363636;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

.otpInputs {
  display: flex;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-bottom: 15px;
}

.otpInput {
  width: 3rem;
  height: 40px;
  margin: 0 2px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #5e5e5e;
  color: #363636;
  flex: 1;
  text-align: center;
  border-radius: 4px;
}

.otpInput:focus {
  /* Override focus border color to stay black */
  border-color: #5E5E5E;
  outline: 1px solid #5E5E5E; 
}

.verifyButtonInactive {
  background-color: #e5e5e5;
  color: #a8a8a8;
  cursor: not-allowed;
  border-radius: 20px;
  padding: 10px 20px;
  border: none;
}

.verifyButtonActive {
  background-color: #ebc03f;
  color: #363636;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px 20px;
  border: none;
}

.verifyButtonActive:hover {
  background-color: #d1a635;
  cursor: pointer;
}

.verifyButtonInactive,
.verifyButtonActive {
  align-self: flex-start;
}

.verifyButton {
  transition: background-color 0.3s;
  border-radius: 20px;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  width: 130px;
  height: 40px;
  margin-top: 35px;
  color: #a2a2a2;
  font-size: 16px;
}

.verifyButtonActive {
  background-color: #ebc03f;
  color: #363636;
  font-family: "Brown", sans-serif;
  font-weight: 500;
}

.regenerateButtonError {
  background-color: #ebc03f;
}

.regenerateButton {
  color: #363636;
  border-radius: 20px;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  margin-top: 30px;
  background-color: #ebc03f;
  font-size: 16px;
  width: 50%;
}
.regenerateButton:hover {
  background-color: #d1a635;
}

.invalidOtpInput {
  background-color: #e34a4733;
  border: 1px solid #ef7777 !important;
}
.validOtpInput {
  background-color: none;
}

.btnGrp {
  display: flex;
  align-items: center;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

/* Check box style */
.consentContainer {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #999897;
  margin-top: 10px;
}

.consentText {
  color: #A2A2A2; /* Original color */
}

.checkedConsentText {
  color: #363636; /* Color when checkbox is checked */
}

.disabledConsentText {
  color: #A2A2A2; /* Color when checkbox is disabled */
}

.consentContainerParticipant {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #999897;
}

.checkboxItem {
  margin-bottom: 10px;
}
.privacyPolicycheckBox {
  margin-top: -1px !important;
  height: 24px;
  width: 24px;
  margin-right: 5px !important;
}
.linkUnderLine {
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
}

.subHeadingOtp {
  font-size: 14px;
  font-family: "Brown", sans-serif;
  margin-bottom: 1rem;
  line-height: 16px;
  color: #868686;
}

.rdpInvalidOtpInput {
  background-color: #e34a4733;
  border: 1px solid #e34a47 !important;
}

.otpLabels {
  font-family: "Brown", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #363636;
}

.resendOTPMsg {
  font-family: "Brown", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(134, 134, 134, 0.5);
}

.error {
  color: #e34a47;
  margin-bottom: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-left: 2px;
}

.fullPhoneNumber {
  font-family: "Brown", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #363636;
}

@media (max-width: 768px) {
  .flexRow {
    display: none;
  }
  .verifyButtonInactive,
  .verifyButtonActive {
    align-self: center;
    width: 90%;
  }
  .regenerateButton {
    color: #363636;
    border-radius: 20px;
    font-family: "Brown", sans-serif;
    font-weight: 500;
    margin-top: 35px;
    background-color: #ebc03f;
    font-size: 16px;
    width: 100%;
    
  }
  .btnGrp {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .signinTitle {
    display: none;
  }
  .verifyButton {
    width: 100%;
    margin-left: 0;
  }
  .otpLabels {
    font-size: 14px;
    line-height: 14px;
  }
  .subHeadingOtp {
    font-size: 12px;
    line-height: 14px;
  }
  .invalidOtpInput {
    background-color: #ffffff33;
    border: 1px solid #E34A47 !important;
  }
  .rdpInvalidOtpInput {
    background-color: #ffffff33;
    border: 1px solid #E34A47 !important;
  }
}
