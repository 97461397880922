/* SignInScreen.module.css */

.container {
    display: flex;
    height: 100vh; /* Full height of the viewport */
    margin: 0;
    padding: 0;
     background-image: url("../../../assets/images/pattern-background.png")
  }
  
  .imageSection {
    flex: 3; /* Adjusted to take up 3/5 of the space */
    background-size: cover;
    background-position: center;
    background-image: url("../../../assets/images/login\ image.png");
  }
  
  .logo {
    position: absolute; /* Absolute positioning relative to the welcomeContainer */
    top: 2.5rem; /* Spacing from the top */
    left: 2.5rem; /* Spacing from the left */
    width: 54px; /* Fixed width, adjust as necessary */
    height: 68.6px; /* Maintain aspect ratio */
    z-index: 10;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  
  .signInSection {
    flex: 2; /* Adjusted to take up 2/5 of the space */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff; /* Assuming a white background */
    margin-top: 2rem;
    background-image: url("../../../assets/images/pattern-background.png");
  }
  
  .signInBox {
    width: 100%; /* Change from auto to 100% to take the full width */
    max-width: 400px; /* Set a max-width if you want to limit the width on larger screens */
    padding: 20px;
    margin-bottom: 9rem; /* Adjust or remove as necessary */
    margin-left: auto; /* Center the signInBox */
    margin-right: auto;
  }
  
  .signinTitle {
    font-family: "Brown", sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 3.5rem;
    margin-top: 3rem;
    color: #363636;
    font-size: 32px;
  }
  
  .signInHeading {
    color: #ebc03f;
    font-family: "Brown", sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .inputGroup {
    margin-bottom: 20px;
  }
  
  .flexRow {
    display: flex;
  }
  
  
  button {
    width: 40%;
    padding: 10px;
    background-color: #e5e5e5; /* Bootstrap primary color */
    color: #a8a8a8;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  
  .otpButtonInactive {
    background-color: #e5e5e5;
    color: #a8a8a8;
    cursor: not-allowed;
    border: none; /* Assuming you don't want any border for the inactive state */
    border-radius: 20px;
    font-family: "Brown", sans-serif;
    font-weight: 500;
  }
  
  .otpButton {
    background-color: #E5E5E5;
    color: #A2A2A2;
    border: none;
    margin-left: -1.5px;
    border-radius: 20px;
    margin-top: 30px;
    font-family: "Brown", sans-serif;
    font-weight: 500;
    width: 150px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    cursor: not-allowed;
  }

  .otpButtonActive {
    background-color: #ebc03f;
    color: #2F2F2F;
    cursor: pointer;
  }

  .otpButtonActive:hover {
    background-color: #d1a635;
  }
  
  .error {
    color: #f18a89;
    margin-top: 10px; /* Adjust as needed */
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
  
  .errorInput {
    background-color: #fbece9;
    border: 1px solid #f5b9b7;
  }


  .fieldContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px; /* Adjust spacing as needed */
  }
  
  .fieldLabel {
    margin-bottom: 12px; /* Adjust spacing as needed */
    font-family: "Brown", sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  

  .textField:-webkit-autofill,
  .textField:-webkit-autofill:hover, 
  .textField:-webkit-autofill:focus, 
  .textField:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

  .textField:disabled {
    background-color: #f9f9f9 !important; 
    border: 1px solid #e5e5e5;
  }
  .textField {
    height: 48px;
    padding: 10px;
    border: 1px solid #5E5E5E;
    border-radius: 4px;
    font-family: "Brown", sans-serif;
    font-size: 16px;
  }
  
  .textField:focus {
    /* Override focus border color to stay black */
    border-color: #5E5E5E;
    outline: 1px solid #5E5E5E; 
  }
  
  .textField::placeholder { /* Most modern browsers support this now. */
    color:rgba(134, 134, 134, 0.5) !important; 
  }
  /* Firefox specific style */
  @-moz-document url-prefix() {
    .textField::placeholder {
      color: #4e4e4e !important;
    }
  }
  .error {
    color: #ED6464;
  }
  
  .inputError {
    border: 1px solid rgba(227, 74, 71, 1);
  }
  
  @media (max-width: 768px) {
    /* or another breakpoint that suits your design */
    .container {
      flex-direction: column; /* Stack the flex items vertically on smaller screens */
      overflow: auto;
    }
  
    .imageSection {
      flex: none; /* Override the flex property for smaller screens */
      width: 100%; /* Make each section take full width of the container */
      height: 40vh; /* Optional: each section takes half of the viewport height */
    }
  
    .signInSection {
      display: flex;
      justify-content: center;
      align-items: normal;
      background: #fff; /* Assuming a white background */
      margin-top: 0px;
      height: 60vh;
      width: 100%;
      flex: none;
    }

    .signinTitle {
      font-family: "Brown", sans-serif;
      font-weight: 400;
      letter-spacing: 0px;
      margin-bottom: 1rem;
      margin-top: 0rem;
      color: #363636;
      font-size: 20px;
    }
  
    .fieldLabel {
      font-size: 14px;
    }

    .signInHeading {
      font-size: 16px;
      margin-bottom: 10px;
    }
  
    .signInBox {
      margin-bottom: 0;
      padding: 15px;
    }

    .textField {
      height: 40px;
      font-size: 14px;
    }

    .textField::placeholder { /* Most modern browsers support this now. */
      color:rgba(134, 134, 134, 0.5) !important; 
      font-size: 14px;
    }
   
    button{
      width: 100%;
    }

    .otpButton {
      width: 100%;
    }
  
    .otpButtonActive {
      background-color: #ebc03f;
      color: #2F2F2F;
      cursor: pointer;
      border: none;
      margin-left: -1.5px;
      border-radius: 20px;
      margin-top: 25px;
      font-family: "Brown", sans-serif;
      font-weight: 500;
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
    }
    .otpButtonActive:hover {
      background-color: #d1a635;
    }
    .buttonStyle {
      width: 100%; /* Full width to align the button */
      -webkit-tap-highlight-color: transparent;
      justify-content: center !important; /* Center button horizontally */
      align-items: center; /* Center button vertically if needed */
      margin-top: 0px; /* Add some space above the button */
    }
    .logo {
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 54px; /* Fixed width, adjust as necessary */
      height: 68.6px; /* Maintain aspect ratio */
      z-index: 10;
    }
  }
  
  