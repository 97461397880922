/* ParticipantDiagnostic.module.css */

.diagnosticContainer {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  max-width: 100%;
}

.diagnosticHeader {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #b89536;
  letter-spacing: -1px;
}

.passcodeTitle {
  font-size: 24px;
  margin-bottom: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #363636;
  letter-spacing: -1px;
}

.passcodeSubtitle {
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #363636;
  word-spacing: -1px;
}

.passcodeInputs {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 48px;
  
}

.maskIcon {
  height: 20px;
  width: 20px;
  align-items: center;
  margin: auto 10px;
  cursor: pointer;
}

.passcodeBox {
  width: 60px;
  height: 48px;
  border: 0.5px solid #8e8f90;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #fff;
  color: #2f2f2f;
  border-radius: 4px;
}
.passcodeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(6 * (60px + 10px) + 20px + 10px);
}

.helpText {
  font-size: 16px;
  color: rgb(37 38 41 / 53%);
  color: #363636;
  cursor: pointer;
  text-decoration: underline;
  font-family: "Brown", sans-serif;
  font-weight: 100 !important;
}

/* Add responsive design and additional styling as needed */
.diagnosticContent {
  font-family: "Brown", sans-serif;
  font-size: 12px;
  text-align: justify;
  font-weight: 400;
  color: #a2a2a2;
  word-spacing: -1.5px;
  max-width: calc(6 * (60px + 10px) + 20px + 10px - 35px); /* This should match the .passcodeWrapper width */
  text-align: justify;
  margin-left: 0px;
  margin-right: auto;
  padding: 0;
  box-sizing: border-box;
} 
.diagnosticText{
  letter-spacing: -1px;
  color: #A2A2A2;
  font-family: "Brown", sans-serif;
  font-size: 12px;
  font-weight: 400;
  word-spacing: 1px;
}

.diagnosticImgText{
  background-color: rgba(235, 192, 63, 0.2);
  width: 584px;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 1px solid #ebc03f;
  border-radius: 4px;
}
.diagWrapper{
  display: flex;
  justify-content: start;
  padding: 8px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}
.diagnosticImgtxtConetnt{
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #363636;
}
.vrImg{
  margin-right: 15px;
}

@media (max-width: 768px) {
  .diagnosticImgText{
    width: 100%;
  }
  .diagnosticImgtxtConetnt{
    font-family: "Brown", sans-serif;
    font-weight: 400;
    color: #363636;
    font-size: 12px;
  }
  .diagnosticImgtxt{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .diagnosticHeader {
    display: none;
  }
}

.diagnosticVrImg {
  display: flex;
  align-items: center;
  justify-content: center;
}