.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Overlay background */
  display: flex;
  align-items: flex-start; /* Align to the top */
  justify-content: center; /* Center horizontally */
  z-index: 1000; /* High z-index to be on top */
}

.modal {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 66px;
}

.searchInputContainer{
  position: relative;
  width: 100%;
}

.searchInput {
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%; /* or any other fitting width */
  border-radius: 5px;
  border: 1px solid #ddd;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  border-radius: 25px;
  height: 55px;
  padding-left: 15px;
}
.searchInput::placeholder{
  color: rgba(134, 134, 134, 0.5) !important;
}
.clearIcon{
  position: absolute;
  right: 20px;
  top: 17px;
  width: 8px;
  height: 8px;
  color: #5E5E5E;
}
.magnifyGlassIcon{
  position: absolute;
  right: 25px;
  top: 19px;
  width: 16px;
  height: 16px;
}
.searchInput:focus-visible {
  outline: none;
}
