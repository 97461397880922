
.headerSection {
  background-color: #fcf8e8;
  border-radius: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 15px 0;
  padding: 15px 20px;
}

.title {
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #5e5e5e;
  font-size: 20px;
  line-height: 15.4px;
}

.controls {
  display: flex;
  align-items: center;
}

/* AdminUserTable.module.css */
.searchInputContainer{
  position: relative;
}
.searchInput {
  padding-left: 15px ; /* Add right padding to make space for the icon */
  border: none;
  border-radius: 25px;
  margin-right: 10px;
  width: 341px;
  height: 40px;
  background-color: white;
  background-position: right 10px center;
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 16px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  border: 0.5px solid transparent;
}
.clearIcon{
  position: absolute;
  right: 23px;
  top: 12px;
  width: 15px !important;
  height: 15px !important;
  color: #5E5E5E;
}
.magnifyGlassIcon{
  position: absolute;
  right: 25px;
  top: 12px;
  width: 16px;
  height: 16px;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.searchInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A2A2A2 !important;
  padding-left: 0px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

/* Firefox specific style */
@-moz-document url-prefix() {
  .searchInput::placeholder {
    color: #595858 !important;
  }
}

.searchInput:focus {
  outline: none; /* Remove the default outline */
  border: 0.5px solid #EBC03F; /* Apply the desired border color and thickness */
}

.filterSection {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  width: 108px;
  border-radius: 30px;
  background-color: #fff;
  justify-content: center;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #868686;
  margin-right: 5px;
  -webkit-tap-highlight-color: transparent;
}

.filterIcon {
  margin-left: -10px;
  margin-right: 3px; 
  color: #A2A2A2;
}

.createUserButton {
  height: 40px;
  width: 171px;
  border-radius: 24px;
  color: #2f2f2f;
  background-color: #e8ba49;
  font-family: "Brown", sans-serif;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
}

.createUserButton:hover {
  background-color: #d1a635;
}

/* Additional styles for your table, buttons, and inputs as needed */

.iconTextWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterSection {
  position: relative;
}

/* ... existing styles ... */

.filterDropdown {
  position: absolute;
  top: 100%; /* Position the dropdown below the filter section */
  left: 0;
  right: 10px; /* Adjust this value to push the dropdown to the left */
  transform: translateX(
    -10%
  ); /* This will move it left by 10% of the dropdown width */
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Ensure it's above other elements */
  width: 250px; 
  display: flex;
  flex-direction: column;
}

.filterContentWrapper {
  margin: 15px;
  width: 220px;
}

.filterHeader {
  font-size: 16px;
  margin-bottom: 10px;
  color: #868686;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  margin-left: 3px;
}

.filterCategory {
  margin-bottom: 15px;
}

.filterTitle {
  font-size: 18px;
  margin-bottom: 5px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: #2f2f2f;
}

.filterOption {
  display: flex;
  align-items: center;
  width: fit-content;
}

.filterOption label {
  cursor: pointer; /* Show pointer on hover over the label */
  font-size: 16px;
  color: #5e5e5e;
  font-family: "Brown", sans-serif;
  font-weight: 400;
}

.applyFilterButton {
  background-color: rgba(94, 94, 94, 1); /* Use your primary color here */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 0 0 12px 12px;
  cursor: pointer;
  text-align: center;
  display: block;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  margin: 0;
  font-size: 18px;
  line-height: 13.86px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  /* No margin-top here, so the button sits directly below the filter options */
}

.applyFilterButton:hover:not(:disabled) {
  background-color: #ebc03f;
  color: #fff;
}

.applyFilterButton:disabled {
  cursor: not-allowed;
}

/* This ensures the filter dropdown does not close when clicking inside it */
.filterDropdown > * {
  pointer-events: auto;
}

.filterSection > * {
  pointer-events: none;
}

.filterSection {
  pointer-events: auto;
}
.errorText {
  color: #e34a47;
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
  margin-top: 10px;
  font-family: "Brown", sans-serif;
}
.ageBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 80%;
  margin-left: 8px;
}

.fromLabel,
.toLabel {
  color: #686868;
  font-size: 14px;
  font-family: "Brown", sans-serif;
}
.filterText {
  color: #A2A2A2;
}

/* ... */
@media (max-width: 899px) {
  .createUserButton {
    width: 141px;
  }
  .filterSection {
    width: 98px;
  }
  .searchInput {
    width: 281px;
  }
}


@media (max-width: 769px) {
  .userDetailsTable {
    width: 100%;
    font-family: Arial, sans-serif;
    background-color: #fff;
    overflow-x: hidden; /* Enables horizontal scrolling */
    overflow-y: hidden; /* Prevents vertical scrolling */
  }
  .headerSection {
    display: none;
  }
  .userDetailsContainer {
    background-color: #fff !important;
  }
}
