.iframeContainer {
  flex: 1;
  height: 420px;
  overflow: hidden;
  background-color: #f6f6f6;
  position: relative;
  border-radius: 12px;
}


iframe {
  border: none;
}


.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}


@media (max-width: 1400px) and (max-height: 800px) {
  .gridContainer {
    grid-template-columns: 1fr; 
  }
}

.iframeBox {
  width: 750px;
  height: 550px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.75);
}


.dialogIframeWrapper {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.dialogIframeScaler {
  width: 750px;
  height: 550px;
  transform: scale(0.95);
  transform-origin: center center;
}

.dialogIframe {
  width: 100%;
  height: 100%;
  border: none;
}