.container {
  background-color: #fcf8e8;
  height: 100%;
  padding-top: 22px;
  padding-bottom: 35px;
  padding-inline: 25px;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.formContainer {
  min-height: 90vh;
  background-color: #fff;
  width: 100%;
  margin: auto;
  border-radius: 12px;
  padding: 20px 20px 15px 20px;
  /*display: flex;*/
  flex-direction: column; /* Stacks child elements vertically */
  justify-content: space-between;
}

.blocks {
  margin-bottom: 15px;
}

.simulationHeading {
  color: #5e5e5e;
  font-size: 20px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  margin-bottom: 25px;
}

.labels {
  color: #5e5e5e;
  font-size: 14px;
  font-family: "Brown", sans-serif;
}

.dropdown {
  border-radius: 4px;
  font-size: 16px;
  border: 1px #5e5e5e;
  margin-right: 5px;
  margin-top: 8px;
  cursor: pointer;
  background-color: white;
  height: 48px !important;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  color: black !important;
  min-width: 110px;
  width: 100%;
}

.checkboxWrapper {
  margin-right: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkboxWrapper .MuiCheckbox-root.Mui-disabled {
  cursor: not-allowed;
}

.materialsTopFloorContainer {
  display: flex;
}

.footerBtnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end; 
  align-items: flex-end; 
  margin-top: auto; 
  height: 100%;
}


.backBtn {
  color: #363636;
  background-color: #fff;
  border-radius: 29px;
  width: 100%;
  border: 1px solid #5E5E5E;
  margin-left: 0px;
  height: 44px;
}

.backBtn:disabled{
  color: #E5E5E5;
  border: 1px solid #E5E5E5;
  cursor: not-allowed;
}

.nextBtn {
  color: #a2a2a2;
  background-color: #e5e5e5;
  border-radius: 29px;
  width: 220px;
  margin-left: 0px;
  height: 44px;
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.nextBtnEnable{
  background-color: #ebc03f;
  border-radius: 29px;
  margin-left: 0px;
  height: 44px;
  color: #363636;
}
.nextBtn:disabled{
  color: #a2a2a2;
  background-color: #e5e5e5;
}

.checkboxParametersWrapper {
  display: flex;
}
.viewMoreAlignment {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  
}
.viewMoreText{
  background-color: #fcf8e8 !important;
  padding: 7px 12px;
  border-radius: 23px;
  gap: 10px;
  font-family: "Brown", sans-serif;
  color: #ebc03f;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-size: 11px;
}

.gendercontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  column-gap: 9.9%;
}


.maincontainer {
  display: flex;
  width: 100%;
}

.genderdropdownBlock {
  width: 103%;
  margin-bottom: 10px;
}

.genderdropdownBlock:nth-child(2) {
  margin-left: -14%; 
} 

.genderdropdownBlock:nth-child(3) {
  grid-column: span 1;
}

.genderselectDropdown {
  margin-bottom: 0;
}

.leftSection {
  flex: 0 0 41%;
  padding-right: 10px; 
}

.rightSection {
  flex: 0 0 59%;
  padding-left: 15px; 
}

.verticalLine {
  border-left: 1.2px solid #E5E5E5; 
  height: 280px; 
  margin: 15px; 
  margin-top: 11%;
}

.customTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  height: 378px;
}

.customTable td {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  padding: 8px;
}

.rightTableSection {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.largeContent {
  background-color: #F9F9F9;
  height: 100%;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 15px;
  overflow: auto;
  border-radius: 5px;
}

.areaList {
  display: flex;
  flex-direction: column;
}

.areaListItem {
  display: flex;
  align-items: center; 
  padding: 9px;
  font-family: "Brown";
  font-weight: 400;
  font-size: 14;
  line-height: 18px;
  margin-bottom: 13px;
  color: #A2A2A2;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.areaListItem:hover {
  background-color: #E5E5E5;
}

.checkboxParametersWrapper {
  display: flex;
  flex-direction: column;
}

.checkboxRow {
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  margin-bottom: 10px; 
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  margin-left: -19px; 
}

.checkboxTable {
  width: 40%;
  border-collapse: collapse;
}

.checkboxTable td {
  text-align: left; 
}

.checkboxTable td {
  padding: 10px;
}

.areaListItem.pressed {
  color: #363636;
}

.areaListItemContent {
  display: flex;
  align-items: center; /* Center vertically */
  width: 100%;
}

.areaIndex, .areaLabel {
  display: flex;
  align-items: center; 
  height: 100%;
}

.areaIndex {
  align-self: flex-start; 
  margin-top: 0; 
  margin-right: 5px; 
}

.materialItemHeading {
  display: flex;
  align-items: center; 
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  color: #A2A2A2;
}

.materialsubHeading{
  display: flex;
  align-items: center; 
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  color: #686868;
}

.materialLabel{
  font-family: "Brown", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #5E5E5E;
}

.materialListItem {
  margin-bottom: 13px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .formContainer {
    /* width: 100vw; */
  }

  .container {
    padding-inline: 8px;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* other styles */
}
