.sliderContainer {
  display: flex;
  align-items: center;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  width: 75px;
}

.inputBox {
  width: 65%;
  text-align: left; /* Align the input value to the left */
  border: 1px solid #ffffff; /* Existing border */
  border-radius: 4px;
  margin-left: 5px;
  outline: none; /* Remove the default outline */
  font-weight: 400;
  font-size: 15px;
  font-family: "Brown", sans-serif;
  color: #363636;
}
.inputBox:disabled{
  background-color: #ffffff;
}
.inputBox:focus {
  border: none; /* Remove the border on focus */
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
}

.arrowIcon {
  color: #5E5E5E;
  cursor: pointer;
  padding: 2px;
  margin: -4px 0; /* Negative margin to close the gap */
}


.arrowIcon:hover {
  background-color: #c0c0c0; 
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
